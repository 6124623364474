import React, { useMemo, useEffect, useState } from 'react';
import useDebounceSearch from '../../../../utils/useDebounceSearch';
import { useAppDispatch } from '../../../context/context-helpers';
import ParticipantsApi from '../../../../api/Participants';
import SelectComponent from '../../../../utils/SelectComponent';
import { components } from 'react-select';
import { useResolveTrad } from '../../../../utils/localization.helpers';
import styles from '../time-slot.module.scss';
import IParticipant from '../../../../api/models/IParticipant';
import { TimeSlotGroup } from '../timeslot-interfaces';

/**
* GroupInput props interface.
* 
* @interface
* @property {Function}       onChange     The callback function.
* @property {TimeSlotGroup[]} groups      The current add groups. 
*/
interface IGroupInputProps {
	onChange: any;
	groups: TimeSlotGroup[];
}

/**
* GroupInput => The group input component.
* 
* @returns {JSX.Element}
*/
const GroupInput: React.FunctionComponent<IGroupInputProps> = ({
	onChange,
	groups
}: IGroupInputProps): JSX.Element => {
	/** The app dispatch */
	const dispatch = useAppDispatch();
	/** The participant api */
	const ParticipantApi = useMemo(() => new ParticipantsApi(dispatch), []);
	/** The isLoading state */
	const [isLoading, setIsLoading] = useState(false);
	/** The debounce search result */
	const { inputValue, setInputValue, search } = useDebounceSearch(ParticipantApi.search, setIsLoading);
	/** The current displayed participant list */
	const [items, setItems] = useState<IParticipant[]>([]);
	/** The initial displayed participant list. */
	const [initialsItems, setInitialsItems] = useState<IParticipant[]>([]);
	/** The Filtered initial participant list. */
	const [filteredInitialsItems, setFilteredInitialsItems] = useState<IParticipant[]>([]);

	/** Function to filter the selected options based on the already existing groups. */
	const filterOnAlreadySelectGroups = (currentItems: IParticipant[]): IParticipant[] => {
		return currentItems.filter((item) => groups.findIndex((group) => group.id === item.id) === -1);
	}

	/** Use effect to initially fill the select options. */
	useEffect(() => {
		setIsLoading(true);
		ParticipantApi.search('', 5, 0).then((res) => {
			if (res.data) {
				let newData = [...res.data]
                newData.forEach(function(element) {
                    element.value = element.id
				})
				setItems(newData);
				setInitialsItems(newData);
				setFilteredInitialsItems(newData);
			} else {
				setItems([]);
				setInitialsItems([]);
				setFilteredInitialsItems([]);
			}
		}).finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (groups && groups.length > 0) {
			let newGroups = [...groups]
			newGroups.forEach(function(element) {
                    element.value = element.id
				})
			let updatedFilteredInitialsItems = initialsItems.filter((item) => newGroups.findIndex((group) => group.id === item.id) === -1);
			updatedFilteredInitialsItems.forEach(function(element) {
				element.value = element.id
			})
			setFilteredInitialsItems(updatedFilteredInitialsItems);
			setItems(updatedFilteredInitialsItems);
		}
	}, [groups.length])

	/** Use effect to change select options when the search result change. */
	useEffect(() => {
		if (isLoading) {
			setIsLoading(false);
		}
		if (!inputValue) {
			let newfilteredInitialsItems = [...filteredInitialsItems]
			newfilteredInitialsItems.forEach(function(element) {
                    element.value = element.id
			})
			setItems(newfilteredInitialsItems);
		}
		if (search.result && search.result.data && search.result.data.length > 0) {
			let items = [...search.result.data]
			items.forEach(function(element) {
                    element.value = element.id
			})
			setItems(filterOnAlreadySelectGroups(items));	
		} else {
			setItems([]);
		}
	}, [search.result, inputValue]);
	
	return (
		<div className={styles.CustomGroupInputContainer}>
				<SelectComponent
					className={styles.CustomGroupInput}
					components={
						{
							Option: CustomOption,
							Placeholder: CustomPlaceholder,
							Control: CustomControl,
							IndicatorSeparator : () => null,
							IndicatorsContainer: () => null
						}
					}
					options={items}
					isLoading={isLoading}
					onInputChange={(value) => setInputValue(value)}
					onChange={(value) => onChange(value)}
					isMulti={false}
					filterOption={() => true}
				/>
		</div>
	);
}

const CustomOption: React.FunctionComponent<any> = ({...props}) => {
    return	<components.Option className="CustomGroupOption" {...props} >
				{props.data.firstName} {props.data.lastName} ({props.data.affiliate.name})
			</components.Option>
}

const CustomControl: React.FunctionComponent<any> = ({...props}) => {
    return <components.Control className="CustomGroupControl" {...props} />
}

const CustomPlaceholder: React.FunctionComponent<any> = ({...props}) => {
	/** The resolved trad for 'add a participants' */
	const addParticipantsTrad = useResolveTrad("EVENTEDITOR.TIME_SLOT.ADD_PARTICIPANTS");

    return (
		<components.Placeholder className="CustomGroupPlaceHolder" {...props}>
			{addParticipantsTrad}
		</components.Placeholder>
	);
}

export default GroupInput;