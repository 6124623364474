/**
 * The user type enum.
 * @enum
 */
export enum UserType {
    Participant = 1,
    Assistant = 2,
    Manager = 4,
    Admin = 8
}

// VALUES
// 1 = Participant
// 2 = Assistant
// 3 = Assistant + Participant
// 4 = Manager
// 5 = Manager + Participant
// 6 = Manager + Assistant
// 7 = Manager + Assistant + Participant
// 8 = Admin
// 9 = Admin + Participant
// 10 = Admin + Assistant
// 11 = Admin +Assistant + Participant
// 12 = Admin + Manager
// 13 = Admin + Manager + Participant
// 14 = Admin + Manager + Assistant
// 15 = Admin + Manager + Assistant + Participant