import BaseApi from './BaseApi';
import { IApiResult } from './api';
import ISettings from './models/ISettings';
import IUserSettings from './models/IUserSettings';
import { DispatchAction } from '../components/context/context';

/**
 * The settings API class.
 * 
 * @class
 */
class SettingsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Settings', dispatch);
    }

	/**
	* Function that get the app settings
	* 
	* @returns {Promise} The request promise.
	*/
	getSettings = (): Promise<IApiResult<ISettings>> => {
		return this._get('', {});
	}

	/**
	* Function that get the user settings
	* 
	* @returns {Promise} The request promise.
	*/
	getUserSettings = (): Promise<IApiResult<IUserSettings>> => {
		return this._get('me', {});
	}
}

export default SettingsApi;