import React, { ReactNode, Children } from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import style from './Header.module.scss';
import { useAppDispatch, useEventEditionStateContext, useUserContext, useOnBehalfContext } from '../context/context-helpers';
import { Context } from '../context/context';
import { LocalizationHelper } from '../../utils/localization.helpers';
import { UserType } from '../../api/enums/UserType';
import NotificationsCount from './NotificationsCount';

/**
 * Header => The app header.
 * 
 * @returns {JSX.Element}
 */

const Header: React.FunctionComponent<{}> = (): JSX.Element => {
    /** The app dispatch function. */
    const dispatch = useAppDispatch();
    /** The event Editor Context */
    const eventEditorContext: Context.IEventEditionState = useEventEditionStateContext();
    /** The user context. */
	const user: Context.IUserContext = useUserContext();
	/** The app dispatch function. */
    const onBehalfUser: Context.IUser = useOnBehalfContext();
    /** Define if the user is a Manager. */
    const isManager = UserType.Manager & (user.userType | onBehalfUser.userType);
    const isParticipant = UserType.Participant & (user.userType | onBehalfUser.userType);
    const isAdmin = UserType.Admin & (user.userType | onBehalfUser.userType);

    let shouldDisplay: number | boolean = isAdmin || isParticipant;
    shouldDisplay = shouldDisplay === 0 ? false : true;
    
    return (
        <div className={style.headerContainer}>
            <div className={style.header}>
                {!eventEditorContext.isOpen && (
                    <div className={style.headerLinks}>
                        <MenuLink to="/mymeetings" label="My meetings" />
                        <MenuLink to="/notifications" label="Notifications">
                            <NotificationsCount />
                        </MenuLink>
                    </div>
                )}
                <div className={style.addMeetingButtonContainer}>
                    {shouldDisplay && !eventEditorContext.isOpen && (
                        <button
                            className={`${style.addMeetingButton} rounded`}
                            onClick={() => dispatch({ type: 'OPEN_EVENT_EDITOR', value: { shouldUseSelectedItem: false, isAReschedule: false } })}
                        >
                            <LocalizationHelper tradkey="EVENTEDITOR.TITLE" />
                        </button>
                    )}
                </div>

            </div>
        </div>
    );
}

export default Header;

/**
 * The MenuLink component props interface.
 * 
 * @interface
 * @property {string}    label      The label to display.
 * @property {string}    to         The link href.
 * @property {ReactNode} [children] Children to render. 
 */
interface IMenuLinkProps {
    label: string,
    to: string,
    children?: ReactNode;
}

/**
 * MenuLink component => The component that render the pages link.
 * 
 * @param   {IMenuLinkProps} props The component props.
 * @returns {JSX.Element}    The component.
 */
const MenuLink: React.SFC<IMenuLinkProps> = ({
    label,
    to,
    children
}: IMenuLinkProps): JSX.Element => {
    let match = useRouteMatch(to);
    return (
        <div className={`${style.menuLink} ${match ? style.headerLinkIsActive : ""}`}>
            <Link className={style.headerLink} to={to}>{label}</Link>
            {children}
        </div>
    );
}