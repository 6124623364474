import React, { useState, useMemo } from 'react';
import { LocalizationHelper } from '../../utils/localization.helpers';
import ModalHeader from '../modal-wrapper/template/ModalHeader';
import ModalFooter from '../modal-wrapper/template/ModalFooter';
import ModalContent from '../modal-wrapper/template/ModalContent';
import { closeModal } from '../modal-wrapper/modal-wrapper.helpers';
import IMeeting from '../../api/models/IMeeting';
import MeetingsApi from '../../api/Meetings';
import { DispatchAction } from '../context/context';
import { useRefresh, useAppDispatch, useLocationContext, useSettingsContext } from '../context/context-helpers';

/**
 * DeleteMeetingModal props interface.
 * 
 * @interface
 * @property {string}                         title    The popup title.
 * @property {string}                         text     The text to display.
 * @property {React.Dispatch<DispatchAction>} dispatch The app dispatch function.
 * @property {IMeeting}                       meeting  The current selected meeting.
 * @property {any}							  refresh  The app refresh function.
 */
interface IDeleteMeetingModalProps {
	title: string;
	text: string;
	dispatch: React.Dispatch<DispatchAction>;
	meeting: IMeeting;
	refresh: any;
}

/**
 * DeleteMeetingModal => The delete meeting modal component.
 * 
 * @returns {JSX.Element}
 */
const DeleteMeetingModal: React.FunctionComponent<IDeleteMeetingModalProps> = ({
	title,
	text,
	dispatch,
	meeting,
	refresh
}: IDeleteMeetingModalProps): JSX.Element => {
	/** Define is the request is sent or not. */
	const [isLoading, setIsLoading] = useState(false);
	/** The meeting api instance. */
	const meetingsApi = useMemo(() => new MeetingsApi(dispatch), []);

	const onConfirm = () => {
		if (!isLoading) {
			setIsLoading(true);
			meetingsApi.deleteMeeting(meeting)
				.then(() => {
					dispatch({ type: 'SELECT_MEETING', value: {} });
				}).finally(() => {
					setIsLoading(false);
					refresh();
					closeModal();
				})
		}
	}

	return (
		<>
			<ModalHeader title={title} />
			<ModalContent>
				<LocalizationHelper tradkey={text} />
			</ModalContent>
			<ModalFooter
				primaryTitle="GLOBAL.YES"
				primaryAction={onConfirm}
				secondaryTitle="GLOBAL.NO"
				secondaryAction={closeModal}
				isLoading={isLoading}
			/>
		</>
	);
}

export default DeleteMeetingModal;