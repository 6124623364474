import React, { useState, useMemo, useEffect } from 'react';
import style from './notifications.module.scss';
import { useAppDispatch, useMeetingContext, useRefresh } from '../context/context-helpers';
import NotificationsApi from '../../api/Notifications';
import NotificationItem from './NotificationItem';
import moment from 'moment';
import IMeeting from '../../api/models/IMeeting';
import InfiniteScroll from '../../utils/InfiniteScroll';
import INotification from '../../api/models/INotification';
import AppLoader from '../../utils/AppLoader';
import { Context } from '../context/context.d';
import { useNotificationsRefresh, useOnBehalfContext } from '../context/context-helpers';

/**
 * Notifications props interface.
 * 
 * @interface
 */
interface INotificationsProps {
}

/** The page size for notification request. */
const NOTIFICATIONS_PAGE_SIZE = 10;

/**
 * Notifications => The Notifications page.
 * 
 * @param   {INotificationsProps} props The component props.
 * @returns {JSX.Element}
 */
const Notifications: React.FunctionComponent<INotificationsProps> = ({}: INotificationsProps): JSX.Element => {
	/** The notifications items list. */
	const [notifications, setNotifications] = useState<INotification[]>([]);
	/** The current page of result. */
	const [pageIndex, setPageIndex] = useState<number>(-1);
	/** Define if the component is in a loading state. */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	/** The dispatch function. */
	const dispatch = useAppDispatch();
	/** The notification api request. */
	const notificationsApi = useMemo(() => new NotificationsApi(dispatch), []);
	/** The select event context */
	const selectedEvent: IMeeting = useMeetingContext();
	/** Define if the notifications page should refresh. */
	const shouldNotificationsRefresh: boolean = useNotificationsRefresh();
	/** Onbehalf context */
	const onBehalfContext = useOnBehalfContext();
	/** Initial onbehalf user */
	const [initOnBehalf, setInitOnBehalf] = useState<Context.IUser>(onBehalfContext);
	/* Refresh helper */
	const refresh = useRefresh();

    useEffect(() => {
        if (shouldNotificationsRefresh) {
			fetchNotifications(true);
			dispatch({type: 'SHOULD_NOTIFICATIONS_REFRESH', value: false});
        }
	}, [shouldNotificationsRefresh])

	useEffect(() => {
		if (pageIndex !== -1) {
			fetchNotifications(false);
		}
	}, [pageIndex]);

	useEffect(() => {
        // If onBehalf change we fetch notifications again
        if(
            (initOnBehalf.userId && onBehalfContext.userId) &&
            (initOnBehalf.userId !== onBehalfContext.userId)
        ) {
			refresh();
        }

        setInitOnBehalf(onBehalfContext);
    }, [onBehalfContext.userId])

	useEffect(() => {
		refresh();
	}, [])
	
	const fetchNotifications = (shouldRefresh: boolean) => {
		setIsLoading(true);
		notificationsApi.getNotifications(NOTIFICATIONS_PAGE_SIZE, shouldRefresh ? 0 : pageIndex)
			.then((res) => {
				if (res && res.data && res.data.length > 0) {
					setNotifications(shouldRefresh ? [...res.data] : [...notifications, ...res.data]);
					if (pageIndex === 0 || shouldRefresh) {
						selectEvent(res.data[0]);
						setPageIndex(0);
					}
				} else if (shouldRefresh) {
					setNotifications([]);
					setPageIndex(0);
				}
			}).finally(() => {
				setIsLoading(false);
			});
	}

	const selectEvent = (notif: INotification) => {
		const selectedEvent: IMeeting = {
			meetingId: notif.meetingId,
			invitationId: notif.invitationId,
			title: notif.meetingTitle,
			description: notif.meetingDescription,
			startDate: new Date(notif.meetingStartDate),
			endDate: new Date(notif.meetingEndDate),
			roomName: notif.roomName,
			roomId: notif.roomId,
			cateringId: notif.cateringId,
			authorName: notif.authorName,
			authorId: notif.authorId,
			invitationStatus: notif.invitationStatus,
			meetingType: notif.meetingType,
			notificationId: notif.notificationId
		}
		dispatch({ type: 'SELECT_MEETING', value:  selectedEvent});
	}

	return (
		<InfiniteScroll classNames={[style.notifications]} callback={() => setPageIndex(pageIndex + 1)} >
			{notifications.length > 0 && (
				<>
					{notifications.map((notification, index) => {
						let content = [];
						const itemDate = moment(notification.createdDate);

						if (index === 0 || itemDate.isAfter(moment(notifications[index - 1].createdDate), 'day')) {
							content.push(
								<span className={style.dateSeparator}>{itemDate.format("dddd Do,")}</span>
							);
						}
						
						content.push(<NotificationItem
							key={notification.notificationId}
							item={notification}
							classNames={[
								(selectedEvent && selectedEvent.notificationId === notification.notificationId ? 'selected' : null),
								(content.length > 0 ? 'firstOfTheDay' : null)
							]}
							onClick={() => selectEvent(notification)}
						/>)

						return content;
					})}
				</>
			)}
			{isLoading && (
				<div className={style.notificationsLoaderContainer}>
					<AppLoader />
				</div>
			)}
		</InfiniteScroll>
	);
}

export default Notifications;
