import React, { useState, Fragment, useMemo, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from '../add-meeting/add-meeting-form.module.scss';
import Select from 'react-select';
import { statutForm } from '../../enums/formMeetingState';
import { LocalizationHelper } from '../../utils/localization.helpers';
import timeOptions from './TimeOptions';
import moment from 'moment';
import RoomsApi from '../../api/Rooms'
import IMeeting from '../../api/models/IMeeting';
import { useAppDispatch, useEventEditionStateContext, useUserContext, useOnBehalfContext } from '../context/context-helpers';
import { Context } from '../context/context';
import { UserType } from '../../api/enums/UserType';



const cateringOptions = [
    { value: null, label: 'Nothing' },
    { value: 1, label: 'Sweet' },
    { value: 2, label: 'Salty' }
]

const START_DATE = new Date("04-05-2020")
const END_DATE = new Date("04-08-2020")

class CustomInput extends React.Component<any> {
    render() {
        return (
            <div className="wrapper">
                <i onClick={this.props.onClick} className="far fa-calendar-alt"></i>
                <input onClick={this.props.onClick} value={this.props.value} type="text" readOnly />
            </div>
        )
    }
}

const formatOptionLabel = ({ id, name, available }) => (
    <div className={style.SelectRoomContainer}>
        {available ? (
            <div className={style.dotTrue}></div>
        ) : (<div className={style.dotFalse}></div>)}
        <div className={style.selectRoomName}>{name}</div>
        {available ? (
            <div className={style.selectRoomStatut}>(available)</div>
        ) : (<div className={style.selectRoomStatut}>(not available)</div>)}
    </div>
);


interface IAddMeetingProps {
    meetingParent: any,
    setMeeting: any,
    resetOptionsSelectTime: any,
    setResetOptionsSelectTime: any,
    status: statutForm,
    scrollContent: any,
}

interface RoomsList {
    id: number,
    name: string,
    available: boolean,
    currentMeetingAuthor: any,
    value?: number,
}

/**
 * EventEditor => The event editor popup.
 * 
 * @returns {JSX.Element}
 */

const AddMeeting: React.FunctionComponent<IAddMeetingProps> = ({
    meetingParent,
    setMeeting,
    resetOptionsSelectTime,
    setResetOptionsSelectTime,
    status,
    scrollContent,
}: IAddMeetingProps): JSX.Element => {

    const user: Context.IUserContext = useUserContext();
    /** The app dispatch function. */
    const onBehalfUser: Context.IUser = useOnBehalfContext();
    /** Define if the user is a Manager. */
    const isManager = UserType.Manager & (user.userType | onBehalfUser.userType);
    const isParticipant = UserType.Participant & (user.userType | onBehalfUser.userType);
    const isAdmin = UserType.Admin & (user.userType | onBehalfUser.userType);

    moment().utcOffset()


    const [rooms, setRooms] = useState<RoomsList[]>([]);

    const [beforeTable, setBeforeTable] = useState([]);
    const [afterTable, setAfterTable] = useState([]);

    const dispatch = useAppDispatch();
    const roomsApi = useMemo(() => new RoomsApi(dispatch), []);

    useEffect(() => {
        if (resetOptionsSelectTime === true) {
            let newTimeTable = [...timeOptions]
            setBeforeTable(newTimeTable)
            setAfterTable(newTimeTable)
            setResetOptionsSelectTime(false);
        }
    }, [resetOptionsSelectTime]);

    useEffect(() => {
        // on check si on est manager ou participant pour mettre à jour le type du meeting
        if (isManager !== 0) {
            const meeting = { ...meetingParent }
            meeting.category.business = false;
            meeting.category.function = true;
            setMeeting(meeting)

        } else if (isParticipant !== 0) {
            const meeting = { ...meetingParent }
            meeting.category.business = true;
            meeting.category.function = false;
            setMeeting(meeting)
        }
        if (status === statutForm.CREATION) {
            let newTimeTable = [...timeOptions]
            setBeforeTable(newTimeTable)
            setAfterTable(newTimeTable)
        }
    }, []);

    useEffect(() => {
        if ((status === statutForm.EDIT || status === statutForm.PROPOSE) && meetingParent.firstHour && meetingParent.secondHour) {
            let newAfterTable = [...timeOptions]
            newAfterTable.forEach(element => {
                for (let i = 0; i < newAfterTable.length; i++) {
                    if (meetingParent.firstHour.value[0] > newAfterTable[i].value[0]) {
                        newAfterTable.splice(i, 1);
                    }
                    else if (meetingParent.firstHour.value[0] === newAfterTable[i].value[0] && meetingParent.firstHour.value[1] >= newAfterTable[i].value[1]) {
                        newAfterTable.splice(i, 1);
                    }
                }
            });
            setAfterTable(newAfterTable)
            let newBeforeTable = [...timeOptions]
            newBeforeTable.forEach(element => {
                for (let i = 0; i < newBeforeTable.length; i++) {
                    if (meetingParent.secondHour.value[0] < newBeforeTable[i].value[0]) {
                        newBeforeTable.splice(i, 1);
                    }
                    else if (meetingParent.secondHour.value[0] === newBeforeTable[i].value[0] && meetingParent.secondHour.value[1] <= newBeforeTable[i].value[1]) {
                        newBeforeTable.splice(i, 1);
                    }
                }
            });
            setBeforeTable(newBeforeTable)
        }
        if (meetingParent.sartDate !== null && meetingParent.endDate !== null && meetingParent.day !== null &&
            meetingParent.startDate.getDay() !== meetingParent.day.getDay()) {
            const meeting = { ...meetingParent }
            const hourStartDate = meetingParent.startDate.getHours()
            const minStartDate = meetingParent.startDate.getMinutes()
            const hourEndDate = meetingParent.endDate.getHours()
            const minEndDate = meetingParent.endDate.getMinutes()
            meeting.startDate = new Date(moment(new Date(meeting.day).setHours(hourStartDate, minStartDate)).format("DD MMM YYYY hh:mm a"))
            meeting.endDate = new Date(moment(new Date(meeting.day).setHours(hourEndDate, minEndDate)).format("DD MMM YYYY hh:mm a"))
            setMeeting(meeting)
            if (meetingParent.category.function === true) {
                roomsApi.findARoom(meeting.startDate, meeting.endDate)
                    .then(({ data }) => {
                        let newData = [...data]
                        newData.forEach(function (element) {
                            element.value = element.id
                        })
                        setRooms(newData)
                    })
            }
        }
    }, [meetingParent]);

    const handleChange = event => {
        const { name, value } = event.target
        const meeting = { ...meetingParent }
        meeting[name] = value
        setMeeting(meeting);
    }

    const handleChangeElem = (event, name) => {
        const meeting = { ...meetingParent }
        meeting[name] = event
        if (name === 'firstHour') {
            let newTimeTable = [...timeOptions]
            newTimeTable.forEach(element => {
                for (let i = 0; i < newTimeTable.length; i++) {
                    if (event.value[0] > newTimeTable[i].value[0]) {
                        newTimeTable.splice(i, 1);
                    }
                    else if (event.value[0] === newTimeTable[i].value[0] && event.value[1] >= newTimeTable[i].value[1]) {
                        newTimeTable.splice(i, 1);
                    }
                }
            });
            setAfterTable(newTimeTable)
        }
        if (name === 'secondHour') {
            let newTimeTable = [...timeOptions]
            newTimeTable.forEach(element => {
                for (let i = 0; i < newTimeTable.length; i++) {
                    if (event.value[0] < newTimeTable[i].value[0]) {
                        newTimeTable.splice(i, 1);
                    }
                    else if (event.value[0] === newTimeTable[i].value[0] && event.value[1] <= newTimeTable[i].value[1]) {
                        newTimeTable.splice(i, 1);
                    }
                }
            });
            setBeforeTable(newTimeTable)
        }
        if (name === 'day' && meeting.firstHour !== null) {
            meeting.startDate = new Date(moment(new Date(meeting.day).setHours(meeting.firstHour.value[0] + 2, meeting.firstHour.value[1])).format("DD MMM YYYY hh:mm a"))
        }
        if (name === 'day' && meeting.secondHour !== null) {
            meeting.endDate = new Date(moment(new Date(meeting.day).setHours(meeting.secondHour.value[0] + 2, meeting.secondHour.value[1])).format("DD MMM YYYY hh:mm a"))
        }
        if (name === 'firstHour' && meeting.day !== '') {
            meeting.startDate = new Date(moment(new Date(meeting.day).setHours(event.value[0] + 2, event.value[1])).format("DD MMM YYYY hh:mm a"))
        }
        if (name === 'secondHour' && meeting.day !== '') {
            meeting.endDate = new Date(moment(new Date(meeting.day).setHours(event.value[0] + 2, event.value[1])).format("DD MMM YYYY hh:mm a"))
        }
        if (meeting.startDate !== null && meeting.endDate !== null && meeting.category.function === true) {
            roomsApi.findARoom(meeting.startDate, meeting.endDate)
                .then(({ data }) => {
                    let newData = [...data]
                    newData.forEach(function (element) {
                        element.value = element.id
                    })
                    setRooms(newData)
                })
        }
        if (name === 'room') {
            meeting.room = event.id
        }
        if (name === 'day' || name === 'firstHour' || name === 'secondHour' && rooms.length > 0 && status !== statutForm.EDIT) {
            // TODO reset la valeur du champ select room
            // meeting.room = null;
        }
        setMeeting(meeting);
    };

    const updateCategory = () => {
        const meeting = { ...meetingParent }
        meeting['category'].business = !meetingParent.category.business
        meeting['category'].function = !meetingParent.category.function
        setMeeting(meeting);
    };

    return (
        <div className={style.addMeetingContainer}>
            <div className={style.contentContainer}>
                <form autoComplete="off">
                    <div className={status === statutForm.CREATION || status === statutForm.EDIT ? `${style.mainRow}` : `${style.mainRow} ${style.mainRowReverse}`}>
                        <div className={style.mainColumn}>
                            <div className={style.column}>
                                <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.DATE" />
                                <div className={style.rowInformationDay}>
                                    {status === statutForm.CREATION ? (
                                        <DatePicker
                                            selected={meetingParent.day}
                                            onChange={(e) => handleChangeElem(e, 'day')}
                                            customInput={<CustomInput />}
                                            className={style.datepicker}
                                            dateFormat="dd/MM/yy"
                                            value={meetingParent.day}
                                            minDate={START_DATE}
                                            maxDate={END_DATE}
                                        // disabled
                                        />
                                    ) : (<></>)}
                                    {status === statutForm.EDIT ? (
                                        <DatePicker
                                            selected={meetingParent.day}
                                            onChange={(e) => handleChangeElem(e, 'day')}
                                            customInput={<CustomInput />}
                                            className={style.datepicker}
                                            dateFormat="dd/MM/yy"
                                            value={meetingParent.day}
                                            minDate={START_DATE}
                                            maxDate={END_DATE}
                                            disabled
                                        />
                                    ) : (<></>)}
                                    <Select
                                        className={style.littleInput}
                                        classNamePrefix=""
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="time"
                                        options={beforeTable}
                                        placeholder={""}
                                        value={meetingParent.firstHour}
                                        onChange={(e) => handleChangeElem(e, 'firstHour')}
                                        onFocus={scrollContent}
                                    />
                                    <div className={style.textBtwInput}> <LocalizationHelper tradkey="EVENTEDITOR.ADD.FORM.TO" /> </div>
                                    <Select
                                        className={style.littleInput}
                                        classNamePrefix=""
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="time"
                                        value={meetingParent.secondHour}
                                        options={afterTable}
                                        placeholder={""}
                                        onChange={(e) => handleChangeElem(e, 'secondHour')}
                                        onFocus={scrollContent}
                                    />
                                </div>
                            </div>
                            {/* On check si on est en mode creation, edition ou propose */}
                            {status === statutForm.CREATION ? (
                                <Fragment>
                                    <div className={style.column}>
                                        <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.CATERING" />
                                        <Select
                                            className={style.littleInput}
                                            classNamePrefix=""
                                            defaultValue={cateringOptions[0]}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="catering"
                                            options={cateringOptions}
                                            placeholder={""}
                                            onChange={(e) => handleChangeElem(e, 'catering')}
                                            onFocus={scrollContent}
                                        />
                                    </div>
                                    {/* {isAdmin ? (
                                             <div className={style.column}>
                                             <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.CATEGORY" />
                                             <div className={style.row}>
                                                 <div className={`${style.rowContainer} ${style.firstElem}`}>
                                                     <label className={style.bcontain}>
                                                         <LocalizationHelper className={style.checkBoxText} tradkey="EVENTEDITOR.ADD.FORM.CATEGORY.BM" />
                                                         <input type="checkbox" checked={meetingParent.category.business} onChange={updateCategory} />
                                                         <div className={style.binput}></div>
                                                     </label>
 
                                                 </div>
                                                 <div className={style.rowContainer}>
                                                     <label className={style.bcontain}>
                                                         <LocalizationHelper className={style.checkBoxText} tradkey="EVENTEDITOR.ADD.FORM.CATEGORY.FM" />
                                                         <input type="checkbox" checked={meetingParent.category.function} onChange={updateCategory} />
                                                         <div className={style.binput}></div>
                                                     </label>
                                                 </div>
                                             </div>
                                         </div>
                                        ) : (<></>)} */}
                                </Fragment>
                            ) : (
                                    <Fragment></Fragment>
                                )}
                            {status === statutForm.EDIT ? (
                                <Fragment>
                                    <div className={style.column}>
                                        <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.CATERING" />
                                        <input name='catering' value={meetingParent.catering} disabled />
                                    </div>
                                </Fragment>
                            ) : (
                                    <Fragment></Fragment>
                                )}
                        </div>
                        {/* On check si on est en mode creation, edition ou propose */}
                        {status === statutForm.CREATION || status === statutForm.EDIT ? (
                            <Fragment>
                                <div className={style.mainColumn}>
                                    <div className={style.column}>
                                        <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.TITLE" />
                                        <input autoComplete="off" name='title' value={meetingParent.title} onChange={handleChange} />
                                    </div>
                                    {/* on check si c'est un function meeting, si oui on affiche le select room */}
                                    {meetingParent.category.function === true && status === statutForm.CREATION ? (
                                        <div className={style.column}>
                                            <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.ROOM" />
                                            <Select
                                                className={style.littleInput}
                                                classNamePrefix=""
                                                defaultValue={meetingParent.room}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="room"
                                                options={rooms}
                                                placeholder={""}
                                                onChange={(e) => handleChangeElem(e, 'room')}
                                                formatOptionLabel={formatOptionLabel}
                                                onFocus={scrollContent}
                                            />
                                        </div>
                                    ) : (<></>)
                                    }
                                    {meetingParent.category.function === true && status === statutForm.EDIT ? (
                                        <div className={style.column}>
                                            <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.ROOM" />
                                            <input name='room' value={meetingParent.roomName} disabled />
                                        </div>
                                    ) : (<></>)
                                    }
                                </div>
                                <div className={style.mainColumn}>
                                    <div className={style.column}>
                                        <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.DESCRIPTION" />
                                        <textarea rows={5} value={meetingParent.description} name='description' onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                                <Fragment>
                                    <div className={`${style.mainColumn} ${style.commentColumn}`}>
                                        <div className={style.column}>
                                            <LocalizationHelper cmpt="label" tradkey="EVENTEDITOR.ADD.FORM.COMMENT" />
                                            <textarea rows={5} value={meetingParent.comment} name='comment' onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddMeeting;