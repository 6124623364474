import React, { useEffect, useState, useMemo } from 'react';
import style from './event-editor.module.scss';
import styleBis from '../modal-wrapper/modal-wrapper.module.scss';
import { useEventEditionStateContext, useAppDispatch, useMeetingContext, useUserContext, useOnBehalfContext, useRefresh } from '../context/context-helpers';
import { Context } from '../context/context';
import ModalHeader from '../modal-wrapper/template/ModalHeader';
import ModalContent from '../modal-wrapper/template/ModalContent';
import ModalFooter from '../modal-wrapper/template/ModalFooter';
import StepTitle from './StepTitle';
import AddMeetingForm from '../add-meeting/AddMeetingForm';
import TimeSlot from './timeslot/TimeSlot';
import MeetingsApi from '../../api/Meetings';
import InvitationsApi from '../../api/Invitations'
import { TimeSlotGroup } from './timeslot/timeslot-interfaces';
import { openModal } from '../modal-wrapper/modal-wrapper.helpers';
import ParticipantsTable from '../participants-table/ParticipantsTable';
import IInvitationParticipant from '../../api/models/IInvitiationParticipant';
import { statutForm } from '../../enums/formMeetingState';
import moment from 'moment';
import timeOptions from '../add-meeting/TimeOptions'
import ConfirmationCreateMeeting from '../modals/ConfirmationCreateMeeting'
import { useSettingsContext } from '../context/context-helpers';


/**
 * EventEditor props interface.
 * 
 * @interface
 */
interface IEventEditorProps {
}
/**
 * EventEditor => The event editor popup.
 * 
 * @returns {JSX.Element}
 */
const EventEditor: React.FunctionComponent<IEventEditorProps> = ({
}: IEventEditorProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const meetingsApi = useMemo(() => new MeetingsApi(dispatch), []);
    const invitationsApi = useMemo(() => new InvitationsApi(dispatch), []);
    // TODO Change the title of the page base on the edit status.
    const eventEditorContext: Context.IEventEditionState = useEventEditionStateContext();
    const meetingContext: Context.IMeetingContext = useMeetingContext();
    const settingsContext: Context.ISettingsContext = useSettingsContext();

    /** Define if the component is loading or not. */
    const [isLoading, setIsLoading] = useState(false);
    /** The current state of the creation / edition form. */
    const [resetOptionsSelectTime, setResetOptionsSelectTime] = useState(false);
    /** The app refresh. */
    const refresh = useRefresh();
    const [meeting, setMeeting] = useState({
        participants: [],
        guests: [],
        invitationId: null,
        meetingId: null,
        day: null,
        firstHour: null,
        secondHour: null,
        startDate: null,
        endDate: null,
        title: '',
        description: '',
        comment: '',
        catering: null,
        cateringId: null,
        room: null,
        roomName: '',
        category: {
            business: false,
            function: true,
        },
    });
    /** The current selected users for the meeting. */
    const [selectedUsers, setSelectedUsers] = useState<TimeSlotGroup[]>([]);

    useEffect(() => {
        if (eventEditorContext.isOpen && eventEditorContext.shouldUseSelectedItem && meetingContext) {
            meetingsApi.getParticipantsByMeetingId(meetingContext.meetingId)
                .then((res) => {
                    const baseSelectedUsers: TimeSlotGroup[] = res.data.map((participant: IInvitationParticipant): TimeSlotGroup => {
                        return {
                            id: participant.participantId,
                            value: participant.participantId,
                            participantId: participant.participantId,
                            firstName: participant.firstName,
                            lastName: participant.lastName,
                            affiliate: {
                                name: (participant.affiliate ? participant.affiliate.name : ''),
                            },
                            function: {
                                name: (participant.function ? participant.function.name : ''),
                            },
                            title: `${participant.firstName} ${participant.lastName}`,
                            height: 35
                        }
                    })
                    setSelectedUsers(baseSelectedUsers);
                });
                console.log(meetingContext)
            const prefilledMeeting = { ...meeting };
            prefilledMeeting.invitationId = meetingContext.invitationId;
            prefilledMeeting.title = meetingContext.title;
            prefilledMeeting.description = meetingContext.description;
            prefilledMeeting.category.business = meetingContext.meetingType === 0 ? true : false;
            prefilledMeeting.category.function = meetingContext.meetingType === 1 ? true : false;
            prefilledMeeting.roomName = meetingContext.roomName;
            prefilledMeeting.room = meetingContext.roomId;
            prefilledMeeting.cateringId = meetingContext.cateringId;
            prefilledMeeting.catering = meetingContext.cateringId === 1 ? 'Sweet' : meetingContext.cateringId === 2 ? 'Salty' : 'Nothing';
            prefilledMeeting.meetingId = meetingContext.meetingId;
            // bidouille pour gerer les string & date de contextMeeting et l'envoyer dans editmeeting
            let hourStartDate = null;
            let minStartDate = null;
            let hourEndDate = null;
            let minEndDate = null;
            let temporyStartDate = null;
            let temporyEndDate = null;
            if (typeof meetingContext.startDate === 'string' || typeof meetingContext.endDate === 'string') {
                temporyStartDate = new Date(meetingContext.startDate)
                temporyEndDate = new Date(meetingContext.endDate)
                prefilledMeeting.day =temporyStartDate;
                hourStartDate = temporyStartDate.getHours() - 2
                minStartDate = temporyStartDate.getMinutes()
                hourEndDate = temporyEndDate.getHours() - 2
                minEndDate = temporyEndDate.getMinutes()
                
            } else {
                prefilledMeeting.day = meetingContext.startDate;
                hourStartDate = meetingContext.startDate.getHours()
                minStartDate = meetingContext.startDate.getMinutes()
                hourEndDate = meetingContext.endDate.getHours()
                minEndDate = meetingContext.endDate.getMinutes()
            }
            for (var i = 0; i < timeOptions.length; i++) {
                if (hourStartDate === timeOptions[i].value[0] && minStartDate === timeOptions[i].value[1]) {
                    prefilledMeeting.firstHour = timeOptions[i];
                }
            }
            for (var i = 0; i < timeOptions.length; i++) {
                if (hourEndDate === timeOptions[i].value[0] && minEndDate === timeOptions[i].value[1]) {
                    prefilledMeeting.secondHour = timeOptions[i];
                }
            }
            prefilledMeeting.startDate = new Date(moment(new Date(prefilledMeeting.day).setHours(hourStartDate + 2, minStartDate)).format("DD MMM YYYY hh:mm a"))
            prefilledMeeting.endDate = new Date(moment(new Date(prefilledMeeting.day).setHours(hourEndDate + 2, minEndDate)).format("DD MMM YYYY hh:mm a"))
            setMeeting(prefilledMeeting);
        }
    }, [eventEditorContext.isOpen]);

    const onSubmit = () => {
        setIsLoading(true);
        if (eventEditorContext.shouldUseSelectedItem && !eventEditorContext.isAReschedule) {
            meetingsApi.editMeeting(
                meeting.meetingId,
                selectedUsers.map(element => element.id),
                meeting.startDate,
                meeting.endDate,
                meeting.cateringId,
                meeting.category.business ? 0 : 1,
                meeting.title,
                meeting.description,
                meeting.room
            ).then((response) => {
                actionOpenConfirmation(true, null, null, null, 2)
                resetMeeting();
                dispatch({ type: 'SELECT_MEETING', value: response.data })
                refresh();
            }, (raison) => {
                actionOpenConfirmation(false, null, null, 10, 2)
            })
            .finally(() => {
                setIsLoading(false);
            })
        } else if (eventEditorContext.shouldUseSelectedItem && eventEditorContext.isAReschedule) {
            invitationsApi.sendReschedule(meeting.invitationId, meeting.startDate, meeting.endDate, meeting.comment)
            .then((response) => {
                actionOpenConfirmation(true, null, null, null, 3)
                refresh();
            }, (raison) => {
                actionOpenConfirmation(false, null, null, 10, 3)
            })
            .finally(() => {
                setIsLoading(false);
            })
        }else {
            meetingsApi.postMeeting(
                selectedUsers.map(element => element.id),
                meeting.startDate,
                meeting.endDate,
                meeting.catering ? meeting.catering.value : meeting.catering,
                meeting.category.business ? 0 : 1,
                meeting.title,
                meeting.description,
                meeting.room
            ).then((response) => {
                actionOpenConfirmation(true, response.data.title, response.data.roomName, null, 1)
                resetMeeting();
                dispatch({ type: 'SELECT_MEETING', value: response.data })
                refresh();
            }, (raison) => {
                actionOpenConfirmation(false, null, null, raison.code, 1)
            })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const resetMeeting = () => {
        setMeeting({
            participants: [],
            guests: [],
            invitationId: null,
            meetingId: null,
            day: '',
            firstHour: null,
            secondHour: null,
            startDate: null,
            endDate: null,
            title: '',
            description: '',
            comment: '',
            catering: null,
            cateringId: null,
            room: null,
            roomName: '',
            category: {
                business: meeting.category.business,
                function: meeting.category.function
            },
        });
        setSelectedUsers([]);
        setResetOptionsSelectTime(true);
    }

    const onConfirm = (participants: TimeSlotGroup[]) => {
        const newParticipants: TimeSlotGroup[] = participants.map(participant => {
            return {
                id: participant.id,
                value: participant.id,
                participantId: participant.id,
                firstName: participant.firstName,
                lastName: participant.lastName,
                affiliate: {
                    name: (participant.affiliate ? participant.affiliate.name : ''),
                },
                function: {
                    name: (participant.function ? participant.function.name : ''),
                },
                title: `${participant.firstName} ${participant.lastName}`,
                height: 35
            }
        })
        setSelectedUsers(newParticipants);
    }

    const isSubmitDisabled = () => {
        if (eventEditorContext.isAReschedule) {
            if (meeting.comment !== '' && meeting.startDate !== null && meeting.endDate !== null) {
                return false
            } else {
                return true
            }
        } else {
            if (meeting.category.business) {
                if (meeting.startDate !== null && meeting.endDate !== null && meeting.title !== '' &&
                    meeting.description !== '' && selectedUsers.length > 0) {
                    return false
                } else {
                    return true
                }
            } else {
                if (meeting.startDate !== null && meeting.endDate !== null && meeting.title !== '' &&
                    meeting.description !== '' && meeting.room !== null) {
                    return false
                } else {
                    return true
                }
            }
        }
    }

    const actionOpenModal = () => {
        openModal(ParticipantsTable, {
            title: "SELECT YOUR PARTICIPANTS",
            text: "CONFIRMATION_MODAL.TEXT.DELETE_EVENT",
            participants: selectedUsers,
            onConfirm: onConfirm,
            settingsContext: settingsContext
        })
    }


    const actionOpenConfirmation = (success, meetingTitle?, roomName?, error?, statut?) => {
        openModal(ConfirmationCreateMeeting, {
            success: success,
            title: success ? "Your meeting has been created" : "Your meeting has not been created",
            meetingTitle: meetingTitle,
            roomName: roomName,
            error: error,
            dispatch,
            statut: statut,
        })
    }

    const resetForm = () => {
        setMeeting({
            participants: [],
            guests: [],
            invitationId: null,
            meetingId: null,
            day: '',
            firstHour: null,
            secondHour: null,
            startDate: null,
            endDate: null,
            title: '',
            description: '',
            comment: '',
            catering: null,
            cateringId: null,
            room: null,
            roomName: '',
            category: {
                business: false,
                function: true,
            },
        });
        setSelectedUsers([]);
    }

    const scrollEnd = () => {
        setTimeout(() => {
            const elements = document.getElementsByClassName(styleBis.modalContent);
            elements[0].scroll({top: 10000000, left: 0, behavior: 'smooth' })
        }, 100)
    }

    return (
        <>
            {eventEditorContext.isOpen && <div className={style.eventEditor}>
                <ModalHeader title="EVENTEDITOR.TITLE" />
                <ModalContent customClassname={style.eventEditor_content}>
                    <StepTitle stepNumber={1} title="EVENTEDITOR.STEP.ONE.TITLE" />
                    <TimeSlot 
                        calendarGroups={selectedUsers}
                        setCalendarGroups={setSelectedUsers}
                        status={
                            eventEditorContext.shouldUseSelectedItem && meetingContext ?
                                (eventEditorContext.isAReschedule ? statutForm.PROPOSE : statutForm.EDIT) :
                                statutForm.CREATION
                        }
                        meetingParent={meeting}
                        setMeeting={setMeeting}
                    />
                    {!eventEditorContext.isAReschedule ? (
                        <div className={style.buttonOpenTab} onClick={actionOpenModal}>
                            <i className="far fa-plus-circle"></i>
                            <div className={style.buttonText}>Or look the list</div>
                        </div>
                    ) : 
                    (<></>)}
                    <StepTitle stepNumber={2} title="EVENTEDITOR.STEP.TWO.TITLE" />
                    <AddMeetingForm
                        meetingParent={meeting}
                        setMeeting={setMeeting}
                        resetOptionsSelectTime={resetOptionsSelectTime}
                        setResetOptionsSelectTime={setResetOptionsSelectTime}
                        scrollContent={scrollEnd}
                        status={
                            eventEditorContext.shouldUseSelectedItem && meetingContext ?
                                (eventEditorContext.isAReschedule ? statutForm.PROPOSE : statutForm.EDIT) :
                                statutForm.CREATION
                        }
                    />
                </ModalContent>
                <ModalFooter
                    //TODO différencier mode creation/edition/proposition
                    primaryTitle={"EVENTEDITOR.ACTION.PRIMARY"}
                    primaryAction={onSubmit}
                    isLoading={isLoading}
                    isPrimaryDisabled={isSubmitDisabled()}
                    secondaryTitle={"GENERAL.CANCEL"}
                    secondaryAction={() => {
                        resetForm();
                        dispatch({ type: 'CLOSE_EVENT_EDITOR', value: null })
                    }}
                />
            </div>}
        </>
    );
}
export default EventEditor;