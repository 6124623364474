import React from 'react';
import style from './event-viewer-header.module.scss';
import IMeeting from '../../../api/models/IMeeting';
import moment from 'moment';

/**
 * EventViewerHeader props interface.
 * 
 * @interface
 * @property {IMeeting} event        The current selected event.
 * @property {boolean}  isReschedule Define if the invitation is a reschedule.
 */
interface IEventViewerHeaderProps {
	event: IMeeting;
	isReschedule: boolean;
}

/**
 * EventViewerHeader => The event viewer header component.
 * 
 * @returns {JSX.Element}
 */
const EventViewerHeader: React.FunctionComponent<IEventViewerHeaderProps> = ({
	event,
	isReschedule
}: IEventViewerHeaderProps): JSX.Element => {
	return (
		<div className={`${style.eventViewerHeader} ${isReschedule ? style.eventViewerHeaderReschedule : ""}`}>
			<span className={style.eventTitle}>{event.title}</span>
			<span className={style.eventAuthor}>{event.authorName}</span>
			<span className={style.eventTime}>
				{moment(event.startDate).format("hh:mma")} - {moment(event.endDate).format("hh:mma")} - {moment(event.startDate).format("dddd Do")}
			</span>
			<button className="no-clickable">{event.roomName}</button>
			<p className={style.eventDescription}>{event.description}</p>
		</div>
	);
}

export default EventViewerHeader;
