import React from 'react';

/**
 * AppLoader props interface.
 * 
 * @interface
 * @property {string}  classNames Class attached to the loader.
 * @property {boolean} isLight    Define if the component should be in light-mode.
 */
interface IAppLoaderProps {
	classNames?: string;
	isLight?: boolean;
}

/**
 * AppLoader => The loader component.
 * 
 * @returns {JSX.Element}
 */
const AppLoader: React.FunctionComponent<IAppLoaderProps> = ({
	classNames,
	isLight
}: IAppLoaderProps): JSX.Element => {
	return (
		<div className={`spinner-border ${classNames} ${isLight ? 'text-light' : ''}`} role="status" />
	);
}

export default AppLoader;