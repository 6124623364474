import React from 'react';
import style from '../modal-wrapper.module.scss';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import AppLoader from '../../../utils/AppLoader';

/**
 * ModalFooter props interface.
 * 
 * @interface
 * @property {string}   primaryTitle      The primary button title.
 * @property {Function} primaryAction     The primary button action.
 * @property {boolean}  isPrimaryDisabled Define if the primary button is disabled.
 * @property {string}   [secondaryTitle]  The secondary button title.
 * @property {Function} [secondaryAction] The secondary button action.
 * @property {string}   [customClassname] An attached custom class.
 * @property {boolean}  [isLoading]       Define if the component should display a loader or not.
 */
interface IModalFooterProps {
	primaryTitle: string
	primaryAction: () => any;
	isPrimaryDisabled?: boolean;
	secondaryTitle?: string;
	secondaryAction?: () => any;
	customClassname?: string;
	isLoading?: boolean;
}

/**
 * ModalFooter => The modal footer component.
 * 
 * @returns {JSX.Element}
 */
const ModalFooter: React.FunctionComponent<IModalFooterProps> = ({
	primaryTitle,
	primaryAction,
	isPrimaryDisabled = false,
	secondaryTitle,
	secondaryAction,
	customClassname = "",
	isLoading = false,
}: IModalFooterProps): JSX.Element => {
	return (
		<div className={`${style.modalFooter} ${customClassname}`}>
			{secondaryTitle && <button className={style.secondaryBtn} onClick={secondaryAction}>
				<LocalizationHelper tradkey={secondaryTitle} />
			</button>}
			<button disabled={isPrimaryDisabled} className={`${style.primaryBtn} rounded`} onClick={primaryAction}>
				<LocalizationHelper tradkey={primaryTitle} />
				{isLoading && <AppLoader classNames={style.modalFooterLoader}/>}
			</button>
		</div>
	);
}

export default ModalFooter;