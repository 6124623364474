import React from 'react';
import style from './event-viewer-content.module.scss';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import { useIsEventViewerCommentAreaInvalid, useAppDispatch } from '../../context/context-helpers';

/**
 * EventViewerContentTextArea props interface.
 * 
 * @interface
 * @property {React.Dispatch<React.SetStateAction<string>>} setCommentValue The setter to set the value of the comment section.
 */
interface IEventViewerContentTextAreaProps {
	setCommentValue: React.Dispatch<React.SetStateAction<string>>
}

/**
 * EventViewerContentTextArea => The event viewer content text area component.
 * 
 * @returns {JSX.Element}
 */
const EventViewerContentTextArea: React.FunctionComponent<IEventViewerContentTextAreaProps> = ({
	setCommentValue
}: IEventViewerContentTextAreaProps): JSX.Element => {
	/** Define if the comment area has error. */
	const isEventViewerCommentAreaInvalid: boolean = useIsEventViewerCommentAreaInvalid();
	/** The app dispatch function. */
	const dispatch = useAppDispatch();
	
	return (
		<>
			<LocalizationHelper cmpt="div" className={style.metaTitle} tradkey="EVENTVIEWER.CONTENT.COMMENT"/>
			<textarea
				className={`${style.commentArea} ${isEventViewerCommentAreaInvalid ? style.invalid : ''}`}
				onChange={(e) => {
					if (isEventViewerCommentAreaInvalid) {
						dispatch({ type: 'SET_EVENT_VIEWER_COMMENT_AREA_HAS_ERROR', value: false});
					}
					setCommentValue(e.target.value);
				}}
			/>
			{isEventViewerCommentAreaInvalid && (
				<LocalizationHelper className={style.invalidCommentAreaLabel} tradkey="EVENTEDITOR.ADD.FORM.COMMENT.INVALID"/>
			)}
		</>
	);
}

export default EventViewerContentTextArea;