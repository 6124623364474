/**
 * The invitation status enum.
 * @enum
 */
export enum InvitationStatus
{
    Pending = 0,
    Refused,
    Accepted,
    Rescheduled,
    RescheduleRefused
}

/**
 * The IInvitationParticipant interface.
 * 
 * @interface
 * @property {number} participantId    The participant id.
 * @property {number} invitationId     The invitation id.
 * @property {number} meetingId        The meeting id.
 * @property {number} invitationStatus The invitation status.
 * @property {string} firstName        The participant's firstname.
 * @property {string} lastName         The participant's lastname.
 * @property {boolean} available       The participant's availability at the meeting time.
 */
export default interface IInvitationParticipant {
	participantId: number;
    invitationId: number;
    meetingId: number;
    invitationStatus: InvitationStatus;
    firstName: string;
    lastName: string;
    function: any;
    affiliate: any;
    available: boolean;
}