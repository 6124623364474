import React, { useState, useEffect } from 'react';
import style from './event-viewer.module.scss';
import EventViewerHeader from './event-viewer-header/EventViewerHeader';
import EventViewerContent from './event-viewer-content/EventViewerContent';
import EventViewerFooter from './event-viewer-footer/EventViewerFooter';
import IMeeting from '../../api/models/IMeeting';
import IInvitationParticipant, { InvitationStatus } from '../../api/models/IInvitiationParticipant';
import MeetingsApi from '../../api/Meetings';
import { useAppDispatch, useEventEditionStateContext, useUserContext, useOnBehalfContext, useIsEventViewerLoadingContext } from '../context/context-helpers';
import { MeetingType } from '../../api/enums/MeetingType';
import AppLoader from '../../utils/AppLoader';
import { Context, DispatchAction } from '../context/context';
import IMeetingDetails from '../../api/models/IMeetingDetails';

/**
 * EventViewer props interface.
 * 
 * @interface
 * @property {IMeeting} event The current selected event.
 */
interface IEventViewerProps {
	event: IMeeting;
}

/**
 * EventViewer => The event viewer root component.
 * 
 * @returns {JSX.Element}
 */
const EventViewer: React.FunctionComponent<IEventViewerProps> = ({
	event
}: IEventViewerProps): JSX.Element => {
	/** The meeting details. */
	const [meetingDetails, setMeetingDetails] = useState<IMeetingDetails>(null);
	/** Define if the component is loader or not. */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	/** The comment section state of the event viewer. */
	const [commentValue, setCommentValue] = useState<string>('');
	/** The event editor modal context. */
	const eventEditorContext: Context.IEventEditionState = useEventEditionStateContext();
	/** The user context. */
	const user: Context.IUserContext = useUserContext();
	/** The app dispatch function. */
	const onBehalfUser: Context.IUser = useOnBehalfContext();
	/** The app dispatch function. */
	const dispatch: React.Dispatch<DispatchAction> = useAppDispatch();
	/** The meeting APi.*/
	const meetingApi: MeetingsApi = new MeetingsApi(dispatch);
	/** The isEventViewerLoading context. */
	const isEventViewerLoading: boolean = useIsEventViewerLoadingContext();

	/** useEffect that fetch the participants of the meeting */
	useEffect(() => {
		if (!event || !event.meetingId) {
			return;
		}

		setIsLoading(true);
		meetingApi.getMeetingDetails(event.meetingId, event.invitationId)
			.then((res) => {
				setMeetingDetails(res.data)
			})
			.finally(() => setIsLoading(false));
	}, [event]);

	return (
		<>
			{!eventEditorContext.isOpen && <div className={`${style.eventViewer} ${style[MeetingType[event.meetingType]]} ${style[InvitationStatus[event.invitationStatus]]}`}>
				{event.meetingId && !isLoading && (
					<>
						<div className={style.overflowContainer}>
							<EventViewerHeader
								event={event}
								isReschedule={event.invitationStatus === InvitationStatus.Rescheduled}
							/>
							<EventViewerContent
								event={event}
								meetingDetails={meetingDetails}
								setCommentValue={setCommentValue}
								connectedUser={onBehalfUser.userId ? onBehalfUser : user}
							/>
						</div>
						<EventViewerFooter
							commentValue={commentValue}
							event={event}
							user={user}
							onBehalfUser={onBehalfUser}
						/>
					</>
				)}
				{isLoading && <AppLoader classNames={style.eventViewerLoader} />}
				{isEventViewerLoading && (
					<div className={style.foregroundLoaderContainer}>
						<AppLoader isLight={true} />
					</div>
				)}
			</div>}
		</>
	);
}

export default EventViewer;
