import React from 'react';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import ModalHeader from '../../modal-wrapper/template/ModalHeader';
import ModalFooter from '../../modal-wrapper/template/ModalFooter';
import ModalContent from '../../modal-wrapper/template/ModalContent';
import { closeModal } from '../../modal-wrapper/modal-wrapper.helpers';
import style from './error-modal.module.scss';

/**
 * ErrorModal props interface.
 * 
 * @interface
 * @property {string} title  The popup title.
 * @property {string} reason The error reason.
 * @property {string} error  The error message.
 */
interface IErrorModalProps {
	title: string;
	reason: string;
	error: string;
}

/**
 * DeleteMeetingModal => The error modal component.
 * 
 * @returns {JSX.Element}
 */
const ErrorModal: React.FunctionComponent<IErrorModalProps> = ({
	title,
	reason,
	error
}: IErrorModalProps): JSX.Element => {

	const onConfirm = () => {
		closeModal();
	}

	return (
		<>
			<ModalHeader title={title} />
			<ModalContent>
				<div className={style.reason}>
					<LocalizationHelper tradkey={"ERROR_MESSAGE_MODAL.REASON"} /> <LocalizationHelper tradkey={reason} />
				</div>
				<div className={style.errorSummaryContainer}>
					<LocalizationHelper
						className={style.errorContent}
						tradkey={error}
					/>
				</div>
			</ModalContent>
			<ModalFooter
				primaryTitle="GLOBAL.OK"
				primaryAction={onConfirm}
			/>
		</>
	);
}

export default ErrorModal;