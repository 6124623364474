import React, { useState, useLayoutEffect, useMemo, useEffect } from 'react';
import moment from 'moment';
import Timeline, {
	TimelineHeaders,
	SidebarHeader,
	CustomHeader,
	Data,
	CustomHeaderPropsChildrenFnProps
} from 'react-calendar-timeline';
import style from './time-slot.module.scss';
import { TimeSlotGroup, SlotItem, SlotMeetings } from './timeslot-interfaces';
import CustomGroup from './custom-group/CustomGroup';
import CustomItem from './custom-item/CustomItem';
import CustomHeaderTime from './custom-header-time/CustomHeaderTime';
import CustomHeaderDate from './custom-header-date/CustomHeaderDate';
import GroupInput from './group-input/GroupInput';
import IParticipant from '../../../api/models/IParticipant';
import { useAppDispatch } from '../../context/context-helpers';
import MeetingsApi from '../../../api/Meetings';
import IParticipantTimeSlots, { ITimeSlot, ITimeSlotMeeting } from '../../../api/models/IParticipantTimeSlots';
import TimeSlotTooltip from './timeslot-details/TimeSlotTooltip';
import { statutForm } from '../../../enums/formMeetingState';

/**
 * TimeSlot props interface.
 * 
 * @interface
 * @property {TimeSlotGroup[]} calendarGroups    The list of the current selected users.
 * @property {Function}        setCalendarGroups Function to update the calendarGroups prop.
 */
interface ITimeSlotProps {
	calendarGroups: TimeSlotGroup[];
	setCalendarGroups: React.Dispatch<React.SetStateAction<TimeSlotGroup[]>>
	status: statutForm;
	meetingParent: any;
    setMeeting: any;
}

/**
 * TimeSlot => The time slot component.
 * 
 * @returns {JSX.Element}
 */
const TimeSlot: React.FunctionComponent<ITimeSlotProps> = ({
	calendarGroups,
	setCalendarGroups,
	status,
	meetingParent,
    setMeeting,
}:ITimeSlotProps): JSX.Element => {
	/** The app dispatch. */
	const dispatch = useAppDispatch();
	/** The participant api. */
	const meetingsApi = useMemo(() => new MeetingsApi(dispatch), []);
	/** The slots (meetings). */
	const [slots, setSlots] = useState<SlotItem[]>([]);
	/** The current scoped date (default is to today at 8:00 am). */
	const [timeStart, setTimeStart] = useState(moment().set('hour', 8).startOf('hour').valueOf());
	/** The current scoped date (default is to today at 8:00 apm). */
	const [timeEnd, setTimeEnd] = useState(moment().set('hour', 20).startOf('hour').valueOf());
	/** The x position of the tooltip */
	const [tooltipPosX, setTooltipPosX] = useState<number>(0);
	/** The y position of the tooltip */
	const [tooltipPosY, setTooltipPosY] = useState<number>(0);
	/** The visibility of the tooltip */
	const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
	/** Meetings for each slot */
	const [slotsMeetings, setSlotsMeetings] = useState<SlotMeetings[]>([]);
	/** Meetings of the current slot */
	const [selectedSlotMeetings, setSelectedSlotMeetings] = useState<ITimeSlotMeeting[]>([]);

	/** Use effect to dipsatch resize event on every render. */
	useLayoutEffect(() => {
		setTimeout(() => {
		  window.dispatchEvent(new Event('resize'));
		}, 0)
	});

	/** Use effect to fetch time slots for every groups when current date is updated. */
	useEffect(() => {
		if (timeStart && calendarGroups.length > 0) {
			meetingsApi.getMeetingsByParticipantsIds(
				calendarGroups.map((group) => group.id),
				moment(timeStart).toISOString()
			)
			.then((res) => {
				const newSlots: SlotItem[] = [];
				const newSlotsMeetings: SlotMeetings[] = [];
				res.data.forEach((participant: IParticipantTimeSlots) => {
					participant.timeslots.forEach((slot: ITimeSlot) => {
						const slotId = new Date().getUTCMilliseconds() + Math.round(Math.random() * 1000)

						newSlots.push({
							id: slotId,
							group: participant.userId,
							title: slot.timeSlotTitle,
							start_time: moment(slot.startDate),
							end_time: moment(slot.endDate)
						})

						newSlotsMeetings.push({
							id: slotId,
							meetings: slot.meetings
						})
					});
				});
				setSlots(newSlots);
				setSlotsMeetings(newSlotsMeetings);
			});
		}
	}, [timeStart]);

	/** Function callback when new date is set. */
	const onDateChange = (newDate, fromSelect) => {
		if (fromSelect) {
			const newMeeting = {...meetingParent}
			newMeeting.day = new Date(newDate.value)
			setMeeting(newMeeting)
		}
		setTimeStart(moment(newDate.value).set('hour', 8).startOf('hour').valueOf());
		setTimeEnd(moment(newDate.value).set('hour', 20).startOf('hour').valueOf())
	}

	/** Function for delete a calendar group. */
	const deleteGroup = (groupId: number) => {
		const deleteItemIndex = calendarGroups.findIndex((group) => group.id === groupId);
		if (deleteItemIndex !== -1) {
			const calendarGroupsCopy: TimeSlotGroup[] = [...calendarGroups];
			let updatedSlots = slots.filter((slot) => slot.group !== groupId);
			calendarGroupsCopy.splice(deleteItemIndex, 1);
			setCalendarGroups([...calendarGroupsCopy]);
			setSlots([...updatedSlots]);
		}
	}

	/** Function for adding a new group. */
	const addNewGroup = (newParticipant: IParticipant) => {
		meetingsApi.getMeetingsByParticipantsIds([newParticipant.id], moment(timeStart).toISOString())
			.then((res) => {
				if (res && res.data && res.data.length) { 
					const slotsCopy: SlotItem[] = [...slots];
					const slotsMeetingsCopy: SlotMeetings[] = [...slotsMeetings];
					const timeslots = res.data[0].timeslots;
					timeslots.forEach((slot: ITimeSlot) => {
						const slotId = new Date().getUTCMilliseconds() + Math.round(Math.random() * 1000)

						slotsCopy.push({
							id: slotId,
							group: newParticipant.id,
							title: slot.timeSlotTitle,
							start_time: moment(slot.startDate).valueOf(),
							end_time: moment(slot.endDate).valueOf()
						})

						slotsMeetingsCopy.push({
							id: slotId,
							meetings: slot.meetings
						})
					});
					setSlots([...slotsCopy]);
					setSlotsMeetings([...slotsMeetingsCopy]);
				}
			}).finally(() => {
				setCalendarGroups([...calendarGroups,
					{
						id: newParticipant.id,
						value: newParticipant.id,
						title: `${newParticipant.firstName} ${newParticipant.lastName}`,
						height: 35,
						participantId: newParticipant.id,
						firstName: newParticipant.firstName,
						lastName: newParticipant.lastName,
						function: {
							name:(newParticipant.function? newParticipant.function.name : ''),
						},
						affiliate: {
							name : newParticipant.affiliate? newParticipant.affiliate.name : '',
						}
					}
				]);
			})
	}

	const updateTooltipPosition = element => {
		const posX = element.getBoundingClientRect().left + (element.offsetWidth / 2);
		const posY = element.getBoundingClientRect().top + element.offsetHeight + 30;

		setTooltipPosX(posX);
		setTooltipPosY(posY);
	}

	const getMeetingsBySlot = itemId => {
		const slotMeetings = slotsMeetings.find(slot => {
			return itemId === slot.id
		});

		setSelectedSlotMeetings(slotMeetings.meetings);
	}

	const onTooltipVisibleChange = (visible) => {
		setTooltipVisible(visible);
	}

	return (
		<div className={style.timeSlot}>
			<div className={style.headerDateContainer}>
				<CustomHeaderDate 
					onDateChange={onDateChange}
					meetingParent={meetingParent}
					setMeeting={setMeeting}
					status={status}
				/>
			</div>
			<Timeline
				canMove={false}
				canResize={false}
				itemTouchSendsClick={false}
				stackItems
				groups={calendarGroups}
				items={slots}
				itemRenderer={CustomItem}
				groupRenderer={(props) => <CustomGroup {...props} deleteGroup={deleteGroup} statusForm={status}/>}
				visibleTimeStart={timeStart}
				visibleTimeEnd={timeEnd}
				sidebarWidth={220}
				onItemClick={() => { setTooltipVisible(!tooltipVisible); }}
				onItemSelect={(itemId, e) => { setTooltipVisible(true); getMeetingsBySlot(itemId); updateTooltipPosition(e.target) }}
				onZoom={() => window.dispatchEvent(new Event('resize'))}
			>
				<TimelineHeaders>
					<SidebarHeader />
					<CustomHeader unit="hour">
						{(props: CustomHeaderPropsChildrenFnProps<Data>) => <CustomHeaderTime {...props} />}
					</CustomHeader>
				</TimelineHeaders>
			</Timeline>
			<TimeSlotTooltip
				visible={tooltipVisible}
				meetings={selectedSlotMeetings}
				onTooltipVisibleChange={onTooltipVisibleChange}
				position={{left: `${tooltipPosX}px`, top: `${tooltipPosY}px`}} />
			{status !== statutForm.PROPOSE ? (
			<div className={`${style.customInputContainer} ${(calendarGroups.length % 2 === 0) ? style.customInputContainerEven : style.customInputContainerOdd}`}>
				<GroupInput onChange={addNewGroup} groups={calendarGroups} />
			</div>
			) : 
			(<></>)}
		</div>
	);
}

export default TimeSlot;
