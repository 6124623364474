import React from 'react';
import { SlotItem } from '../timeslot-interfaces';
import { ItemContext, GetItemsProps, Id} from 'react-calendar-timeline';
import style from '../time-slot.module.scss';
import { MeetingType } from '../../../../api/enums/MeetingType';

/**
* CustomItem props interface.
* 
* @interface
* @property {Function} cb The callback function.
*/
interface ICustomItemProps {
	item: SlotItem;
	itemContext: ItemContext,
	getItemProps: (
		props: GetItemsProps,
		) => {
			key: Id;
			ref: React.Ref<any>;
			className: string;
			onMouseDown: React.MouseEventHandler;
			onMouseUp: React.MouseEventHandler;
			onTouchStart: React.TouchEventHandler;
			onTouchEnd: React.TouchEventHandler;
			onDoubleClick: React.MouseEventHandler;
			onContextMenu: React.ReactEventHandler;
			style: React.CSSProperties;
		};
}
	
/**
* CustomItem => The custom item component.
* 
* @returns {JSX.Element}
*/
const CustomItem: React.FunctionComponent<ICustomItemProps> = ({
	item,
	itemContext,
	getItemProps,
}: ICustomItemProps): JSX.Element => {
	
	return (
		<div {...getItemProps({})}>
			<div className={`rct-item_border`} />
			<div className="rct-item_content" >
				{itemContext.title}
			</div>
		</div>
	);
}

export default CustomItem;