import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useErrorContext } from '../context/context-helpers';
import { Context } from '../context/context';

const Toaster = (): JSX.Element => {
	const error: Context.IAppError = useErrorContext();

	useEffect(() => {
		if (!error || !error.type) {
			return;
		}
		toast.error(error.type);
	}, [error.type]);

	return (
		<ToastContainer
			position="bottom-right"
			autoClose={5000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			draggable
			pauseOnHover
		/>
	);
} 

export default Toaster;