import React from 'react';
import style from './event-viewer-content.module.scss';
import IMeeting from '../../../api/models/IMeeting';
import IInvitationParticipant, { InvitationStatus } from '../../../api/models/IInvitiationParticipant';
import ParticipantSpan from './ParticipantSpan';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import { Context } from '../../context/context';
import { MeetingType } from '../../../api/enums/MeetingType';
import EventViewerContentTextArea from './EventViewerContentTextArea';
import IMeetingDetails from '../../../api/models/IMeetingDetails';
import moment from 'moment';

// TODO ajouter les strings en dur dans le locale.json

/**
 * EventViewerContent props interface.
 * 
 * @interface
 * @property {Context.IUser} 								connectedUser   The current connected user.
 * @property {IMeeting}                                     event           The current selected event.
 * @property {IInvitationParticipant[]}                     participants    The list of participants of the meeting.
 * @property {React.Dispatch<React.SetStateAction<string>>} setCommentValue The setter to set the value of the comment section.
 */
interface IEventViewerContentProps {
	connectedUser: Context.IUser;
	event: IMeeting;
	meetingDetails: IMeetingDetails;
	setCommentValue: React.Dispatch<React.SetStateAction<string>>
}

/**
 * EventViewerContent => The event viewer content component.
 * 
 * @returns {JSX.Element}
 */
const EventViewerContent: React.FunctionComponent<IEventViewerContentProps> = ({
	connectedUser,
	event,
	meetingDetails,
	setCommentValue
}: IEventViewerContentProps): JSX.Element => {
	/** Define if the current displayed meeting is a reschedule. */
	const isProposition =
		event.authorId === connectedUser.userId &&
		event.meetingType === MeetingType.Business &&
		event.invitationStatus === InvitationStatus.Rescheduled;
	/** Define if the current displayed meeting is an invitation that need an answer. */
	const NeedAnswerInvitation =
		event.authorId !== connectedUser.userId &&
		event.meetingType === MeetingType.Business &&
		event.invitationStatus === InvitationStatus.Pending;

	return (
		<div className={style.eventViewerContent}>
			{isProposition && (
				<>
					<LocalizationHelper cmpt="div" className={style.propositionTitle} tradkey="EVENTVIEWER.CONTENT.PROPOSITION"/>
					{meetingDetails.newStartDate && meetingDetails.newEndDate && (
						<>
							<span className={style.metaTitle}>Day and hours</span>
							<span className={style.metaContent}>
								{moment(meetingDetails.newStartDate).format("hh:mma")} - {moment(meetingDetails.newEndDate).format("hh:mma")} - {moment(meetingDetails.newStartDate).format("dddd Do")}
							</span>
						</>
					)}
				</>
			)}
			{meetingDetails && meetingDetails.comment && (
				<>
					<LocalizationHelper cmpt="div" className={style.metaTitle} tradkey="EVENTVIEWER.CONTENT.COMMENT"/>
					<span className={style.metaContent}>{meetingDetails.comment}</span>
				</>
			)}
			{NeedAnswerInvitation && (
				<EventViewerContentTextArea setCommentValue={setCommentValue} />	
			)}
			{meetingDetails && meetingDetails.participants.length > 0 && (
				<>
					<LocalizationHelper cmpt="span" className={style.metaTitle} tradkey="EVENTVIEWER.CONTENT.PARTICIPANTS"/>
					{meetingDetails.participants.map((participant: IInvitationParticipant) => {
						return <ParticipantSpan key={participant.participantId} participant={participant} />
					})}
				</>
			)}
			{isProposition && (
				<EventViewerContentTextArea setCommentValue={setCommentValue} />	
			)}
		</div>
	);
}

export default EventViewerContent;
