import React, { useContext } from 'react';
import { Context, DispatchAction } from './context';
import { AppStateContext } from './AppContext';

/**
 * The useAppStateContext hook.
 * 
 * @returns {Context.IContext} The current app state context.
 */
const useAppStateContext = (): [Context.IContext, React.Dispatch<DispatchAction>] => {
	const contextValue = useContext(AppStateContext)

	return contextValue;
}

/**
 * The useAppDispatch hook.
 * 
 * @returns {React.Dispatch<DispatchAction>} The current dispatch function to update the app state.
 */
const useAppDispatch = (): React.Dispatch<DispatchAction> => {
	const [value, dispatch] = useAppStateContext();
	return dispatch;
}

/**
 * The useLocalizationContext hook.
 * 
 * @returns {Context.ILocalization} The current app localization context.
 */
const useLocalizationContext = (): Context.ILocalization => {
	const [context] = useAppStateContext();

	return context.localization;
}

/**
 * The useUserContext hook.
 * 
 * @returns {Context.IUserContext} The current app user context.
 */
const useUserContext = (): Context.IUserContext => {
	const [context] = useAppStateContext();

	return context.user;
}

/**
 * The useOnBehalfContext hook.
 * 
 * @returns {Context.IUserContext} The current onbehalf user context.
 */
const useOnBehalfContext = (): Context.IUser => {
	const [context] = useAppStateContext();

	return context.onBehalfUser;
}

/**
 * The useMeetingContext hook.
 * 
 * @returns {Context.IMeetingContext} The current app meeting context.
 */
const useMeetingContext = (): Context.IMeetingContext => {
	const [context] = useAppStateContext();

	return context.meeting;
}

/**
 * The useNotificationContext hook.
 * 
 * @returns {Context.INotifications} The current app notifications context.
 */
const useNotificationContext = (): Context.INotifications => {
	const [context] = useAppStateContext();

	return context.notifications;
}

/**
 * The calendarRefresh hook.
 * 
 * @returns {boolean} The current calendar refresh context.
 */
const useCalendarRefresh = (): boolean => {
	const [context] = useAppStateContext();

	return context.calendarRefresh;
}

/**
 * The notifications refresh hook.
 * 
 * @returns {boolean} The current notifications refresh context.
 */
const useNotificationsRefresh = (): boolean => {
	const [context] = useAppStateContext();

	return context.notificationsRefresh;
}

/**
 * The NotificationsCountRefresh hook.
 * 
 * @returns {boolean} The current notificationsCount refresh context.
 */
const useNotificationsCountRefresh = (): boolean => {
	const [context] = useAppStateContext();

	return context.notificationsCountRefresh;
}

/**
 * The isEventViewerCommentAreaInvalid hook.
 * 
 * @returns {boolean} The current state of the event viewer comment area context.
 */
const useIsEventViewerCommentAreaInvalid = (): boolean => {
	const [context] = useAppStateContext();

	return context.isEventViewerCommentAreaInvalid;
}

/**
 * The useErrorContext hook.
 * 
 * @returns {Context.IAppError} The current app error context.
 */
const useErrorContext = (): Context.IAppError => {
	const [context] = useAppStateContext();

	return context.error;
}

/**
 * The useEventEditionStateContext hook.
 * 
 * @returns {Context.IEventEditionState} The current event edition state context.
 */
const useEventEditionStateContext = (): Context.IEventEditionState => {
	const [context] = useAppStateContext();

	return context.eventEditionState;
}

/**
 * The isEventViewerLoading hook.
 * 
 * @returns {boolean} The current isEventViewerLoading context.
 */
const useIsEventViewerLoadingContext = (): boolean => {
	const [context] = useAppStateContext();

	return context.isEventViewerLoading;
}

/**
 * The useSettingsContext hook.
 *
 * @returns {Context.ISettingsContext} The current app settings context.
 */
const useSettingsContext = (): Context.ISettingsContext => {
	const [context] = useAppStateContext();

	return context.settings;
}

/**
 * The useLocationContext hook.
 *
 * @returns {string} The current app location context.
 */
const useLocationContext = (): string => {
	const [context] = useAppStateContext();

	return context.locationPath;
}

/**
 * A function to refresh the appropriate page.
 */
const useRefresh = (): any => {
	/** The app location. */
	const location = useLocationContext();
	/** The app dispatch. */
	const dispatch = useAppDispatch();

	if (location === '/mymeetings') {
		return () => dispatch({type: 'SHOULD_CALENDAR_REFRESH', value: true});
	} else if (location === '/notifications') {
		return () => dispatch({type: 'SHOULD_NOTIFICATIONS_REFRESH', value: true});
	}

	return () => null;
}

export {
	useAppDispatch,
	useUserContext,
	useOnBehalfContext,
	useMeetingContext,
	useNotificationContext,
	useIsEventViewerLoadingContext,
	useIsEventViewerCommentAreaInvalid,
	useNotificationsRefresh,
	useLocalizationContext,
	useErrorContext,
	useEventEditionStateContext,
	useSettingsContext,
	useCalendarRefresh,
	useNotificationsCountRefresh,
	useLocationContext,
	useRefresh
};
