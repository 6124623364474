import React, { useState, useEffect } from 'react';
import style from './ParticipantsTable.module.scss';
import Select from 'react-select';
import ModalHeader from '../modal-wrapper/template/ModalHeader';
import ModalFooter from '../modal-wrapper/template/ModalFooter';
import ModalContent from '../modal-wrapper/template/ModalContent';
import { closeModal } from '../modal-wrapper/modal-wrapper.helpers';
import ParticipantsApi from '../../api/Participants';
import { useAppDispatch } from '../context/context-helpers';
import InfiniteScroll from '../../utils/InfiniteScroll';
import useDebounceSearch from '../../utils/useDebounceSearch';

/**
 * Header => The app header.
 * 
 * @returns {JSX.Element}
 */

const selectGroup = [
    { value: 'MARTELL MUMM PERRIER-JOUËT', label: 'MARTELL MUMM PERRIER-JOUËT' },
    { value: 'Mcdo', label: 'Mcdo' },
    { value: 'HAVANA CLUB INTERNATIONAL', label: 'HAVANA CLUB INTERNATIONAL' },
]

interface IparticipantsTableProps {
	title: string;
    participants: any,
    settingsContext: any,
    onConfirm: (meeting) => any,
}

const formatOptionLabel = ({ name }) => (
    <div className={style.SelectOptionsContainer}>
        <div className={style.selectOptionName}>{name}</div>
    </div>
  );

const ParticipantsTable: React.FunctionComponent<IparticipantsTableProps> = ({
    title,
    participants,
    onConfirm,
    settingsContext,
}: IparticipantsTableProps): JSX.Element => {

    const dispatch = useAppDispatch();
    const participantsApi = new ParticipantsApi(dispatch);


    const [isLoading, setIsLoading] = useState(false);


    const [currentMeeting, setcurrentMeeting] = useState({
        participants: [],
        guests: [],
    });
    const [showParticipants, setshowParticipants] = useState<boolean>(true);
    const [showGuests, setshowGuests] = useState<boolean>(true);
    const [pageIndex, setpageIndex] = useState<number>(0);
    const [filtres, setFiltres] = useState({
        group: [],
        groupValue: null,
        job: [],
        jobValue: null,
        department: [],
        departments: [],
        departmentValue: null,
        search: '',
    })

        /** The debounce search result */
        const { inputValue, setInputValue, search } = useDebounceSearch(participantsApi.search, setIsLoading, true, filtres.jobValue, filtres.groupValue, filtres.departmentValue);

    useEffect(() => {
        const meeting = { ...currentMeeting }
        participantsApi.search('', 10, pageIndex)
        .then(data => {
            meeting['guests'] = data.data
            participants.forEach(element => {
                let index = -1;
                for (let i = 0; i < meeting.guests.length; i++) {
                    if (meeting.guests[i].id === element.id) {
                        index = i;
                    }
                }
                if (index !== -1) {
                    meeting.guests.splice(index, 1);
                    meeting.participants.push(element);
                } 
                if (index === -1) {
                    let secondIndex = -1;
                    for (let i = 0; i < meeting.participants.length; i++) {
                        if (meeting.participants[i].id === element.id) {
                            secondIndex = i;
                        }
                    }
                    if (secondIndex === -1) {
                        meeting.participants.push(element);
                    }
                }
            })
            setcurrentMeeting(meeting)
        })
    }, [participants]);

    useEffect(() => {
        let newFiltres = { ...filtres}
        if (settingsContext) {
            let newAffiliate = [...settingsContext.affiliate]
			newAffiliate.forEach(function(element) {
                    element.value = element.id
            });
            let newFunctions = [...settingsContext.functions]
			newFunctions.forEach(function(element) {
                    element.value = element.id
            });
            let newDepartments = [...settingsContext.departments]
			newDepartments.forEach(function(element) {
                    element.value = element.id
			});
            
            newFiltres.group = newAffiliate
            newFiltres.job = newFunctions
            newFiltres.departments = newDepartments
        }
        setFiltres(newFiltres)
    }, []);

    useEffect(() => {
        if (search && search.result && search.result.data) {
            const meeting = { ...currentMeeting }
            meeting.guests = []
            search.result.data.forEach(element => {
                let index = -1;
                for (let i = 0; i < meeting.participants.length; i++) {
                    if (meeting.participants[i].id === element.id) {
                        index = i;
                    }
                }
                if (index === -1) {
                    meeting.guests.push(element);
                }
            })
            setcurrentMeeting(meeting);
        }
	}, [search.result]);

    const handleChangeElem = (e, name) => {
        if (name === 'group') {
            let newGroupValue = {...filtres}
            if (e) {
                newGroupValue.groupValue = e.id
            } else {
                newGroupValue.groupValue = null
            }
            participantsApi.search(filtres.search, 10, 0, filtres.jobValue, newGroupValue.groupValue , filtres.departmentValue)
            .then(data => {
                const meeting = { ...currentMeeting }
                meeting.guests = []
                data.data.forEach(element => {
                    let index = -1;
                    for (let i = 0; i < meeting.participants.length; i++) {
                        if (meeting.participants[i].id === element.id) {
                            index = i;
                        }
                    }
                    if (index === -1) {
                        meeting.guests.push(element);
                    }
                })
                setcurrentMeeting(meeting)
                setFiltres(newGroupValue)
                setpageIndex(0)
            })
        }
        if (name === 'job') {
            let newJobValue = {...filtres}
            if (e) {
                newJobValue.jobValue = e.id
            } else {
                newJobValue.jobValue = null
            }
            participantsApi.search(filtres.search, 10, 0, newJobValue.jobValue, filtres.groupValue, filtres.departmentValue )
            .then(data => {
                const meeting = { ...currentMeeting }
                meeting.guests = []
                data.data.forEach(element => {
                    let index = -1;
                    for (let i = 0; i < meeting.participants.length; i++) {
                        if (meeting.participants[i].id === element.id) {
                            index = i;
                        }
                    }
                    if (index === -1) {
                        meeting.guests.push(element);
                    }
                })
                setcurrentMeeting(meeting)
                setFiltres(newJobValue)
                setpageIndex(0)
            })
        }
        if (name === 'department') {
            let newDepartmentValue = {...filtres}
            if (e) {
                newDepartmentValue.departmentValue = e.id
            } else {
                newDepartmentValue.departmentValue = null
            }
            participantsApi.search(filtres.search, 10, 0, filtres.jobValue, filtres.groupValue ,newDepartmentValue.departmentValue )
            .then(data => {
                const meeting = { ...currentMeeting }
                meeting.guests = []
                data.data.forEach(element => {
                    let index = -1;
                    for (let i = 0; i < meeting.participants.length; i++) {
                        if (meeting.participants[i].id === element.id) {
                            index = i;
                        }
                    }
                    if (index === -1) {
                        meeting.guests.push(element);
                    }
                })
                setcurrentMeeting(meeting)
                setFiltres(newDepartmentValue)
                setpageIndex(0)
            })
        }
    }

    const handleDeselectAll = () => {
        const meeting = { ...currentMeeting }
        meeting.participants.forEach(element => {
            meeting.guests.push(element);
        })
        meeting.participants = [];
       setcurrentMeeting(meeting)
    }
 
    const handleDeselect = participant => {
        const meeting = { ...currentMeeting }
        meeting.participants.forEach(element => {
            let index = -1;
            for (let i = 0; i < meeting.participants.length; i++) {
                if (meeting.participants[i].id === participant.id) {
                    index = i;
                }
            }
            if (index !== -1) {
                meeting.participants.splice(index, 1);
                meeting.guests.push(participant);
            }
        })
        setcurrentMeeting(meeting)
    }
 
    const handleSelectAll = () => {
        const meeting = { ...currentMeeting }
        meeting.guests.forEach(element => {
            meeting.participants.push(element);
        })
        meeting.guests = [];
        setcurrentMeeting(meeting)
    }
 
    const handleSelect = participant => {
        const meeting = { ...currentMeeting }
        meeting.guests.forEach(element => {
            let index = -1;
            for (let i = 0; i < meeting.guests.length; i++) {
                if (meeting.guests[i].id === participant.id) {
                    index = i;
                }
            }
            if (index !== -1) {
                meeting.guests.splice(index, 1);
                meeting.participants.push(participant);
            }
        })
        setcurrentMeeting(meeting)
    }

    const submit = () => {
        const meeting = { ...currentMeeting };
        onConfirm(currentMeeting.participants);
        closeModal()
    }

    const hideParticipants = () => {
        setshowParticipants(!showParticipants)
    }

    const hideGuests = () => {
        setshowGuests(!showGuests)
    }

    const nextParticipants = () => {
        const meeting = { ...currentMeeting }
        let currentPage = pageIndex
        currentPage++;
        participantsApi.search(filtres.search, 10, currentPage, filtres.jobValue, filtres.groupValue, filtres.departmentValue)
        .then(data => {
            data.data.forEach(element => {
                let index = -1;
                for (let i = 0; i < meeting.participants.length; i++) {
                    if (meeting.participants[i].id === element.id) {
                        index = i;
                    }
                }
                if (index === -1) {
                    meeting.guests.push(element);
                }
            })
            setcurrentMeeting(meeting)
            setpageIndex(currentPage)
        });
    }

    const updateSearch = event => {
        const { name, value } = event.target
        const newSearchFiltre = { ...filtres}

        newSearchFiltre.search = value
        setFiltres(newSearchFiltre);
        setInputValue(value)
    }

        return (
            <>
			<ModalHeader title={title} />
			<ModalContent>
            <div className={`${style.myTab}`}>
                <div className={style.searchContainer}>
                    <div className={style.filtresContainer}>
                        <div className={style.filtresText}>Filtres :</div>
                        <Select
                            className={style.selectCustom}
                            classNamePrefix=""
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="time"
                            options={filtres.group}
                            formatOptionLabel={formatOptionLabel}
                            placeholder={"Choose the group"}
                            onChange={(e) => handleChangeElem(e, 'group')}
                        />
                        <Select
                            className={style.selectCustom}
                            classNamePrefix=""
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="time"
                            options={filtres.job}
                            formatOptionLabel={formatOptionLabel}
                            placeholder={"Choose the job"}
                            onChange={(e) => handleChangeElem(e, 'job')}
                        />
                         <Select
                            className={`${style.selectCustom} ${style.departmentSelect}`}
                            classNamePrefix=""
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="time"
                            options={filtres.departments}
                            formatOptionLabel={formatOptionLabel}
                            placeholder={"Choose the department"}
                            onChange={(e) => handleChangeElem(e, 'department')}
                        />
                    </div>
                    <div className={style.tabSearch}>
                        <input className={style.inputSearch} placeholder={'Search'} value={filtres.search} onChange={updateSearch}/>
                        <i className="far fa-search"></i>
                    </div>
                </div>
                <div className={style.tabContainer}>
                <InfiniteScroll classNames={[style.inconnue]} callback={() => nextParticipants()} >
                    <div className={style.titleTab}>
                        <div>PARTICIPANTS</div>
                        { showParticipants ? (
                            <i onClick={hideParticipants} className="far fa-chevron-up"></i>
                        ): (
                            <i onClick={hideParticipants} className="far fa-chevron-down"></i>
                        )}
                    </div>
                    { showParticipants ? (
                            <table>
                            <thead>
                                <tr className={style.tableHeader}>
                                    <th scope="col" className={style.squareSelect}>
                                        <i className="far fa-check-square" onClick={handleDeselectAll}></i>
                                    </th>
                                    <th scope="col" className={style.headerLabel}>NAME</th>
                                    <th scope="col" className={style.headerLabel}>LAST NAME</th>
                                    <th scope="col" className={style.headerLabel}>JOB</th>
                                    <th scope="col" className={style.headerLabel}>GROUP</th>
    
                                </tr>
                            </thead>
                            <tbody>
                                {currentMeeting.participants.map(participant =>
                                        <tr key={participant.id} className={style.tableElement} onClick={(e) => handleDeselect(participant)}>
                                            <td className={style.squareSelect}>
                                                <i className="far fa-check-square" onClick={(e) => handleDeselect(participant)}></i>
                                            </td>
                                            <td className={style.elementName}>{participant.firstName}</td>
                                            <td className={style.elementName}>{participant.lastName}</td>
                                            <td className={style.elementInfo}>{participant.function?.name}</td>
                                            <td className={style.elementGroup}>{participant.affiliate?.name}</td>
                                        </tr>
                                )}
                            </tbody>
                        </table>
                        ): (
                            <></>
                        )}
                    <div className={`${style.titleTab} ${style.secondTab}`}>
                        <div>GUESTS</div>
                        { showGuests ? (
                            <i onClick={hideGuests} className="far fa-chevron-up"></i>
                        ): (
                            <i onClick={hideGuests} className="far fa-chevron-down"></i>
                        )}
                    </div>
                    { showGuests ? (
                            <table>
                            <thead>
                                <tr className={style.tableHeader}>
                                    <th scope="col" className={style.squareSelect}>
                                        <i className="far fa-square" onClick={handleSelectAll}></i>
                                    </th>
                                    <th scope="col" className={style.headerLabel}>NAME</th>
                                    <th scope="col" className={style.headerLabel}>LAST NAME</th>
                                    <th scope="col" className={style.headerLabel}>JOB</th>
                                    <th scope="col" className={style.headerLabel}>GROUP</th>
    
                                </tr>
                            </thead>
                            <tbody>
                                {currentMeeting.guests.map(participant =>
                                        <tr key={participant.id} className={style.tableElement} onClick={(e) => handleSelect(participant)}>
                                            <td className={style.squareSelect}>
                                                <i className="far fa-square" onClick={(e) => handleSelect(participant)}></i>
                                            </td>
                                            <td className={style.elementName}>{participant.firstName}</td>
                                            <td className={style.elementName}>{participant.lastName}</td>
                                            <td className={style.elementInfo}>{participant.function?.name}</td>
                                            <td className={style.elementGroup}>{participant.affiliate?.name}</td>
                                        </tr>
                                )}
                            </tbody>
                        </table>
                        ): (
                            <></>
                        )}
                </InfiniteScroll>
                </div>
            </div>
			</ModalContent>
			<ModalFooter
				primaryTitle="GLOBAL.YES"
				primaryAction={submit}
				secondaryTitle="GLOBAL.NO"
				secondaryAction={closeModal}
			/>
		</>
        );
    }

export default ParticipantsTable;