import React, { useMemo } from 'react';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import ModalHeader from '../../modal-wrapper/template/ModalHeader';
import ModalFooter from '../../modal-wrapper/template/ModalFooter';
import ModalContent from '../../modal-wrapper/template/ModalContent';
import { closeModal } from '../../modal-wrapper/modal-wrapper.helpers';
import IMeeting from '../../../api/models/IMeeting';
import style from './meeting-acceptation-summary-modal.module.scss';
import moment from 'moment';
import { DispatchAction } from '../../context/context';

/**
 * MeetingAcceptationSummaryModal props interface.
 * 
 * @interface
 * @property {React.Dispatch<DispatchAction>} dispatch   The app dispatch function.
 * @property {IMeeting}                       meeting    The current selected meeting.
 * @property {string}						  contentKey The content key to display.
 * @property {any}							  refresh	 The app refresh function.
 */
interface IMeetingAcceptationSummaryModalProps {
	dispatch: React.Dispatch<DispatchAction>;
	meeting: IMeeting;
	contentKey: string;
	refresh: any;
}

/**
 * MeetingAcceptationSummaryModal => The meeting acceptation summary modal component.
 * 
 * @returns {JSX.Element}
 */
const MeetingAcceptationSummaryModal: React.FunctionComponent<IMeetingAcceptationSummaryModalProps> = ({
	dispatch,
	meeting,
	contentKey,
	refresh
}: IMeetingAcceptationSummaryModalProps): JSX.Element => {

	const onConfirm = () => {
		refresh();
		dispatch({ type: 'SELECT_MEETING', value: meeting });
		closeModal();
	}

	return (
		<>
			<ModalHeader title={'MEETING_ACCEPTATION_SUMMARY_MODAL.TITLE'} />
			<ModalContent>
				<LocalizationHelper
					className={style.contentLabel}
					tradkey={contentKey}
				/>
				<div className={style.summaryContainer}>
					<span className={style.eventTitle}>{meeting.title}</span>
					<span className={style.eventTime}>
						{moment(meeting.startDate).format("hh:mma")} - {moment(meeting.endDate).format("hh:mma")} - {moment(meeting.startDate).format("dddd Do")}
					</span>
					<button>{meeting.roomName}</button>
					<p className={style.eventDescription}>{meeting.description}</p>
				</div>
			</ModalContent>
			<ModalFooter
				primaryTitle="GENERAL.OK"
				primaryAction={onConfirm}
			/>
		</>
	);
}

export default MeetingAcceptationSummaryModal;