import React from 'react';
import styles from './OnbehalfView.module.scss';
import { LocalizationHelper } from '../../utils/localization.helpers';

interface IOnbehalfViewProps {
}

export const OnbehalfView: React.FunctionComponent<IOnbehalfViewProps> = (props: IOnbehalfViewProps): JSX.Element => {


    return (
        <div className={styles.onbehalfView}>
            <img src="/img/calendar.png" alt="" />

            <LocalizationHelper className={styles.title} tradkey={"ONBEHALF.VIEW.SHOW_CALENDAR"} />
            <LocalizationHelper className={styles.text} tradkey={"ONBEHALF.VIEW.CHOICES_LOCATION"} />
        </div>
    )
}