import React from 'react';
import style from '../modal-wrapper.module.scss';
import { LocalizationHelper } from '../../../utils/localization.helpers';

/**
 * ModalHeader props interface.
 * 
 * @interface
 * @property {string} title           The modal header title.
 * @property {string} customClassname An attached custom class.
 */
interface IModalHeaderProps {
	title: string;
	customClassname?: string;
}

/**
 * ModalHeader => The modal header component.
 * 
 * @returns {JSX.Element}
 */
const ModalHeader: React.FunctionComponent<IModalHeaderProps> = ({
	title,
	customClassname = ""
}: IModalHeaderProps): JSX.Element => {
	return (
		<div className={`${style.modalHeader} ${customClassname}`}>
			<LocalizationHelper tradkey={title} />
		</div>
	);
}

export default ModalHeader;