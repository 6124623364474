import BaseApi from './BaseApi';
import { IApiListResult, IApiResult } from './api';
import { DispatchAction } from '../components/context/context';
import INotification from './models/INotification';

/**
 * The notifications API class.
 * 
 * @class
 */
class NotificationsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Notifications', dispatch);
    }

	/**
	* Function that get the current notifications.
	* 
	* @param   {number}	 pageSize  The page size of the request.
	* @param   {number}  pageIndex The page index.
	* @returns {Promise} The request promise.
	*/
	getNotifications = (pageSize: number, pageIndex: number): Promise<IApiListResult<INotification>> => {
		return this._get('', {
			pageSize,
			page: pageIndex
		});
	}

	/**
	* Function that get the unseen notifications number.
	* 
	* @returns {Promise} The request promise.
	*/
	getNotificationsCount = (): Promise<IApiResult<{count: number}>> => {
		return this._get(`count`, {});
	}
}

export default NotificationsApi;