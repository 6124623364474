import { Context, DispatchAction } from "./context";

/**
 * The app context reducer function.
 * 
 * @param   {Context.IContext} state The previous state value.
 * @param   {DispatchAction}   action The action.
 * @returns {Context.IContext} The updated state.
 */
const appReducer = (state: Context.IContext, action: DispatchAction): Context.IContext => {
	switch (action.type) {
		case 'LOCALIZATION_INIT': {
			const newState = {...state};
			return Object.assign(newState, {
				localization: Object.assign(newState.localization, {
					tradKeys: action.value
				})
			});
		}
		case 'REQUEST_ERROR': {
			const newState = {...state};
			return Object.assign(newState, {error: {type: action.value}});
		}
		case 'SELECT_MEETING': {
			const newState = {...state};
			return Object.assign(newState, {meeting: action.value});
		}
		case 'OPEN_EVENT_EDITOR': {
			const newState = {...state};
			return Object.assign(newState, {
				eventEditionState: Object.assign(newState.eventEditionState, {
					isOpen: true,
					shouldUseSelectedItem: action.value.shouldUseSelectedItem,
					isAReschedule: action.value.isAReschedule
				})
			});
		}
		case 'CLOSE_EVENT_EDITOR': {
			const newState = {...state};
			return Object.assign(newState, {
				eventEditionState: Object.assign(newState.eventEditionState, {
					isOpen: false
				})
			});
		}
		case 'SET_EVENT_VIEWER_COMMENT_AREA_HAS_ERROR': {
			const newState = {...state};
			return Object.assign(newState, {isEventViewerCommentAreaInvalid: action.value});
		}
		case 'SET_EVENT_VIEWER_LOADING': {
			const newState = {...state};
			return Object.assign(newState, {isEventViewerLoading: action.value});
		}
		case 'SET_SETTINGS': {
			const newState = {...state};
			return Object.assign(newState, {settings: action.value});
		}
		case 'SET_USER_SETTINGS': {
			const newState = {...state};
			return Object.assign(newState, {
				user: Object.assign(newState.user, action.value)
			});
		}
		case 'SET_ONBEHALF': {
			const newState = {...state};
			return Object.assign(newState, {onBehalfUser: action.value});
		}
		case 'SET_ROUTE': {
			const newState = {...state};
			return Object.assign(newState, {locationPath: action.value});
		}
		case 'SHOULD_CALENDAR_REFRESH': {
			const newState = {...state};
			return Object.assign(newState, {calendarRefresh: action.value});
		}
		case 'SHOULD_NOTIFICATIONS_REFRESH': {
			const newState = {...state};
			return Object.assign(newState, {notificationsRefresh: action.value});
		}
		case 'SHOULD_NOTIFICATIONS_COUNT_REFRESH': {
			const newState = {...state};
			return Object.assign(newState, {notificationsCountRefresh: action.value});
		}
		default:
		  throw new Error();
	}
}

export default appReducer;