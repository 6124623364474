import IMeeting from '../../../api/models/IMeeting';
import { Context } from '../../context/context';
import {actions, IEventViewerAction } from './ActionBtn';
import { UserType } from '../../../api/enums/UserType';
import { InvitationStatus } from '../../../api/enums/InvitationStatus';
import { MeetingType } from '../../../api/enums/MeetingType';
import { useEffect } from 'react';


/**
 * Get left actions function. => Function to get the actions on the left side of the panel.
 *
 * @function 
 * @property {IMeeting}      event        	The current selected event.
 * @property {Context.IUser} user         	The current connected user.
 * @property {Context.IUser} onBehalfUser 	The current onBehalf user.
 * @property {boolean}       isNotification Check if we are in notifications page.
 * @returns  {IEventViewerAction[]} The actions.
 */
const getLeftActions = (
	event: IMeeting,
	user: Context.IUser,
	onBehalfUser: Context.IUser,
	isNotification: boolean,
): IEventViewerAction[] => {

	const connectedUser = onBehalfUser.userId ? onBehalfUser : user;

	let btnsActions: IEventViewerAction[] = [];

	if (event.authorId === connectedUser.userId) {
		if (event.meetingType === MeetingType.Business && event.invitationStatus === InvitationStatus.Rescheduled) {
			// Refuse.
			btnsActions = [actions[7]];
		} else {
			// Add to my calendar.
			btnsActions = [actions[0]];
		}
	} else {
		if (event.meetingType === MeetingType.Business) {
			switch (event.invitationStatus) {
				case InvitationStatus.Pending:
					// Refuse and propose new time.
					btnsActions = [actions[3], actions[4]]
					break;
				default:
					// Add to my calendar.
					btnsActions = [actions[0]];
					break;
			}
		}
	}

	return btnsActions;
}
/**
 * Get right actions function. => Function to get the actions on the right side of the panel.
 *
 * @function 
 * @property {Context.IMeeting} event        The current selected event.
 * @property {Context.IUser}    user         The current connected user.
 * @property {Context.IUser}    onBehalfUser The current onBehalf user.
 * @property {boolean}       isNotification  Check if we are in notifications page.
 * @returns  {IEventViewerAction[]} The actions.
 */
const getRightActions = (
	event: IMeeting,
	user: Context.IUser,
	onBehalfUser: Context.IUser,
	isNotification: boolean,
): IEventViewerAction[] => {
	const isParticipant = UserType.Participant & (user.userType | onBehalfUser.userType);
	const isManager = UserType.Manager & (user.userType | onBehalfUser.userType);
	const isAdmin = UserType.Admin & (user.userType | onBehalfUser.userType);
	const connecterUser = onBehalfUser.userId ? onBehalfUser : user;


	let btnsActions: IEventViewerAction[] = [];

	if (event.authorId === connecterUser.userId) {
		if (event.meetingType === MeetingType.Business) {
			if (event.invitationStatus === InvitationStatus.Rescheduled) {
				// Accept and send.
				btnsActions = [actions[5]];
			} else if ((isParticipant || isAdmin) && !isNotification) {
				// Delete and edit.
				btnsActions = [actions[1], actions[2]]; 
			} else {
				btnsActions = [actions[2]];
			}
		} else if (event.meetingType === MeetingType.Function) {
			if (isManager) {
				// edit.
				btnsActions = [actions[2]];
			} else if (isAdmin && !isNotification) {
				// Delete and edit.
				btnsActions = [actions[1], actions[2]]; 
			} else {
				btnsActions = [actions[2]];
			}
		} else {
			// Lunch et Plenary meetings.
			if (isAdmin && !isNotification) {
				// Delete and edit.
				btnsActions = [actions[1], actions[2]];
			} else {
				btnsActions = [actions[2]];
			}
		}
	} else {
		if (isAdmin && !isNotification) {
			// Delete.
			btnsActions = [actions[1]];
		}
		if (event.meetingType === MeetingType.Business) {
			switch (event.invitationStatus) {
				case InvitationStatus.Pending:
					// Accept.
					btnsActions.push(actions[6]);
			}
		}
	}

	return btnsActions;
}

export { getRightActions, getLeftActions };