import React from 'react';
import BaseApi from './BaseApi';
import { DispatchAction } from '../components/context/context';
import IMeeting from './models/IMeeting';
import { IApiResult } from './api';

/**
 * The invitations API class.
 * 
 * @class
 */
class InvitationsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Invitations', dispatch);
    }

    sendReschedule = (invitationId: number, startDate: string, endDate: string,  comment: string) => {
		return this._put(`${invitationId}/reschedule`, {
			StartDate: startDate,
			EndDate: endDate,
			Comment: comment,
		});
	}
	/**
	* Function that accept an invitation.
	* 
	* @param   {number}  invitationId The invitation id.
	* @returns {Promise} The request promise.
	*/
	accept = (invitationId: number): Promise<IApiResult<IMeeting>> => {
		return this._put(`${invitationId}/accept`, {});
	}

	/**
	* Function that accept a proposition.
	* 
	* @param   {number}  invitationId The invitation id.
	* @returns {Promise} The request promise.
	*/
	acceptReschedule = (invitationId: number): Promise<IApiResult<IMeeting>> => {
		return this._post(`${invitationId}/reschedule/accept`, {});
	}

	/**
	* Function that refuse a proposition.
	* 
	* @param   {number}  invitationId The invitation id.
	* @param   {string}  comment      The invitation comment.
	* @returns {Promise} The request promise.
	*/
	refuseReschedule = (invitationId: number, comment: string): Promise<IApiResult<IMeeting>> => {
		return this._post(`${invitationId}/reschedule/refuse`, {
			comment
		});
	}

	/**
	* Function that refuse an invitation.
	* 
	* @param   {number}  invitationId The invitation id.
	* @param   {string}  comment      The invitation comment.
	* @returns {Promise} The request promise.
	*/
	refuse = (invitationId: number, comment: string): Promise<IApiResult<IMeeting>> => {
		return this._put(`${invitationId}/refuse`, {
			comment
		});
	}

}

export default InvitationsApi;