import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useLocation} from 'react-router-dom';
import { MeetingsCalendar } from './MeetingsCalendar/MeetingsCalendar';
import Notifications from './notifications/Notifications';
import EventEditor from './event-editor/EventEditor';
import { useAppDispatch, useLocationContext } from './context/context-helpers';

/**
 * Routes => The app main routes definition.
 * 
 * @returns {JSX.Element}
 */
const Routes: React.FunctionComponent<{}> = (): JSX.Element => {
	/** The current app location. */
	const location = useLocation();
	/** The app dispatch. */
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (location) {
			dispatch({type: 'SET_ROUTE', value: location.pathname});
		}
	}, [location, dispatch]);

	return (
		<>
			<Switch>
				<Route path="/mymeetings">
					<MeetingsCalendar />
				</Route>
				<Route path="/notifications">
					<Notifications />
				</Route>
				<Redirect to="/mymeetings" />
			</Switch>
			<EventEditor />
		</>
	);
}

export default Routes;
