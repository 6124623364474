import React from 'react';
import style from './notifications.module.scss';
import EventStatePastille from '../../utils/EventStatePastille'
import { InvitationStatus } from '../../api/enums/InvitationStatus';
import { LocalizationHelper } from '../../utils/localization.helpers';
import moment from 'moment';
import INotification from '../../api/models/INotification';

/**
 * INotificationItemProps props interface.
 * 
 * @interface
 * @property {INotification} item       The notification item.
 * @property {string[]}      classNames The attached class names.
 * @property {Function}      onClick    Callback function when a notification is clicked.
 */
interface INotificationItemProps {
	item: INotification;
	classNames?: string[];
	onClick: () => void
}

/**
 * NotificationItem => The notification item component.
 * 
 * @param   {INotificationItemProps} props The component props.
 * @returns {JSX.Element}
 */
const NotificationItem: React.FunctionComponent<INotificationItemProps> = ({
	item,
	classNames,
	onClick
}: INotificationItemProps): JSX.Element => {

	const classList = classNames.map((_class: string) => _class ? style[_class] + ' ' : '').join(" ");

	const content = () => {
		const notificationContent = [];

		switch(item.status) {
			case InvitationStatus.Accepted:
				const morePeopleJSX = <> <LocalizationHelper tradkey="GENERAL.AND" /> {item.additionalUsersCount} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.ACCEPTED_MORE" /> </>;
				return (
					<>
						{item.fromName} {item.additionalUsersCount > 0 ? morePeopleJSX : ""} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.ACCEPTED"/> {item.meetingTitle}.
					</>
				);
			case InvitationStatus.Pending:
				return (
					<>
						{item.fromName} <LocalizationHelper tradkey="GENERAL.OF"/> {item.fromAffiliation} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.PENDING"/>
						<br />
						<b>{item.meetingTitle}</b>
					</>
				);
			case InvitationStatus.Refused:
				return (
					<>
						{item.fromName} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.REFUSED"/> {item.meetingTitle}.
					</>
				)
			case InvitationStatus.RescheduleRefused:
				return (
					<>
						{item.fromName} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.PROPOSITION.REFUSED"/> {item.meetingTitle}.
					</>
				)
			case InvitationStatus.Rescheduled:
				const mStartDate = moment(item.newStartDate);
				const mEndDate = moment(item.newEndDate);

				return (
					<>
						{item.fromName} <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.REFUSED"/> {item.meetingTitle}.
						<br />
						<b>
							<LocalizationHelper tradkey="NOTIFICATIONS.LABEL.RESCHEDULED"/> {mStartDate.format("dddd Do - hha")} <LocalizationHelper tradkey="GENERAL.UNTIL"/> {mEndDate.format("hha")}
						</b>
					</>
				)
			case InvitationStatus.Canceled:
				return (
					<>
						<LocalizationHelper tradkey="NOTIFICATIONS.LABEL.CANCELED.START" /> <b>{item.meetingTitle}</b> <LocalizationHelper tradkey="NOTIFICATIONS.LABEL.CANCELED.END" />.
					</>
				)
			default:
				break;
		}
		return notificationContent;
	}

	return (
		<div className={`${style.notificationItem} ${classList}`} onClick={() => onClick()}>
			<EventStatePastille classNames={[style.itemPastille]} invitationStatus={item.status} />
			<div className={style.itemContent}>{content()}</div>
		</div>
	);
}

export default NotificationItem;