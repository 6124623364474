/**
 * The Meeting type enum.
 * @enum
 */
enum MeetingType {
	Business=0,
	Function,
	Plenary,
	Lunch
}

export { MeetingType }