import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import styles from './MeetingsCalendar.module.scss';
import MeetingsApi from '../../api/Meetings';
import SettingsApi from '../../api/Settings';
import { useAppDispatch, useCalendarRefresh, useOnBehalfContext, useMeetingContext, useRefresh } from '../context/context-helpers';
import IMeeting from '../../api/models/IMeeting';
import ISettings from '../../api/models/ISettings';
import { Context } from '../context/context.d';
import { MeetingType } from '../../api/enums/MeetingType';
import { InvitationStatus } from '../../api/enums/InvitationStatus';
import { MeetingsCalendarView } from './MeetingsCalendarView';
import AppLoader from '../../utils/AppLoader';

moment.locale('en', {
    week: {
        dow: moment().day(),
        doy: 1,
    },
});

const localizer = momentLocalizer(moment)

/**
* MyMeetings props interface.
* 
* @interface
*/
interface IMeetingsCalendarProps {
}

interface IMeetingsCalendarHeaderProps {
    date: Date
    label: string,
    localizer: any
}

interface IMeetingsCalendarEventProps {
    event: any,
    title: string
}

const meetingEventPropGetter = ({ meetingType, invitationStatus }: IMeeting) => {
    return {
        className: `
				${styles.event}
				${styles[MeetingType[meetingType]]}
				${styles[InvitationStatus[invitationStatus]]}
			`
    }
}

/**
 * MeetingsCalendarHeader => The header component.
 *
 * @param   {IMeetingsCalendarHeaderProps} props The component props.
 * @returns {JSX.Element}
 */
const MeetingsCalendarHeader: React.SFC<IMeetingsCalendarHeaderProps> = ({ label }: IMeetingsCalendarHeaderProps): JSX.Element => {
    return (
        <>
            <span className="ddd">{label.split(' ')[0]}</span>
            <span className="dow">{label.split(' ')[1]}</span>
        </>
    )
}

/**
 * MeetingsCalendarEvent => The event component.
 *
 * @param   {IMeetingsCalendarEventProps} props The component props.
 * @returns {JSX.Element}
 */
const MeetingsCalendarEvent: React.SFC<IMeetingsCalendarEventProps> = ({ event }: IMeetingsCalendarEventProps): JSX.Element => {
    return (
        <>
            <div className="title">{event.title}</div>
            <div className="description">{event.description}</div>
        </>
    )
}

/**
 * MeetingsCalendar => The meetings calendar component.
 * 
 * @param   {IMeetingsCalendarProps} props The component props.
 * @returns {JSX.Element}
 */
export const MeetingsCalendar: React.FunctionComponent<IMeetingsCalendarProps> = (props: IMeetingsCalendarProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const settingsApi = new SettingsApi(dispatch);
    const meetingsApi = new MeetingsApi(dispatch);
    const onBehalfContext = useOnBehalfContext();
    const meetingContext = useMeetingContext();

    const [settings, setSettings] = useState<ISettings>({
        startDay: null,
        endDay: null,
        startHour: null,
        endHour: null,
        scrollTime: null,
        affiliations: [],
        functions: [],
        caterings: []
    });
    const [meetings, setMeetings] = useState<IMeeting[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const shouldCalendarRefresh = useCalendarRefresh();
    const [initOnBehalf, setInitOnBehalf] = useState<Context.IUser>(onBehalfContext);
    /** The app refresh. */
    const refresh = useRefresh();

    useEffect(() => {
        if (shouldCalendarRefresh) {
            fetchMeetings();
            refresh();
            dispatch({type: 'SHOULD_CALENDAR_REFRESH', value: false});
        }
    }, [shouldCalendarRefresh])

    const fetchSettings = async() => {
        setIsLoading(true);
        settingsApi.getSettings()
            .then(({ data }) => {
                const settings = {
                    ...data,
                    startDay: new Date(data.startDay),
                    endDay: new Date(data.endDay),
                    startHour: new Date(moment(data.startHour, 'HH:mm').format()),
                    endHour: new Date(moment(data.endHour, 'HH:mm').format()),
                    scrollTime: new Date(moment(data.startHour, 'HH:mm').format())
                }
                setSettings(settings);
                dispatch({ type: 'SET_SETTINGS', value: settings })
            }).finally(() => setIsLoading(false));
    }

    const fetchMeetings = async () => {
        setIsLoading(true);
        meetingsApi.getMeetings()
            .then(({ data }) => {
                const meetings = data.map(meeting => ({ ...meeting, startDate: new Date(meeting.startDate), endDate: new Date(meeting.endDate) }))
                setMeetings(meetings);
            }).finally(() => setIsLoading(false));
    }

    const getSelectedMeeting = function() {
        const sortedMeetings = meetings.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        const selectedMeeting = sortedMeetings.find(meeting => {
            const meetingStartDate = new Date(meeting.startDate).getTime();
            const now = new Date().getTime();
            return meeting.meetingType === 0 && meetingStartDate > now;
        });

        if (selectedMeeting) {
            dispatch({ type: 'SELECT_MEETING', value: selectedMeeting });
        }
    }

    useEffect(() => {
        fetchMeetings();
        fetchSettings();
    }, [])

    useEffect(() => {
        // If onBehalf change we fetch meetings again
        if(
            (initOnBehalf.userId && onBehalfContext.userId) &&
            (initOnBehalf.userId !== onBehalfContext.userId)
        ) {
            fetchMeetings();
        }

        setInitOnBehalf(onBehalfContext);
    }, [onBehalfContext.userId])

    useEffect(() => {
        if(meetings.length) {
            getSelectedMeeting();
        }
    }, [meetings])

    return (
        <>
            {!isLoading && <Calendar
                className={styles.meetingsCalendar}
                localizer={localizer}
                events={meetings}
                defaultView="week"
                views={{ week: MeetingsCalendarView }}
                startAccessor="startDate"
                endAccessor="endDate"
                scrollToTime={settings.scrollTime}
                eventPropGetter={meetingEventPropGetter}
                formats={{
                    timeGutterFormat: 'H:mm',
                    dayFormat: 'ddd Do'
                }}
                toolbar={false}
                components={{
                    event: MeetingsCalendarEvent,
                    week: {
                        header: (MeetingsCalendarHeader as React.SFC)
                    }
                }}
                onNavigate={() => {}}
                date={settings.startDay ? settings.startDay : undefined}
                selected={meetingContext}
                onSelectEvent={event => {
                    dispatch({ type: 'SELECT_MEETING', value: event });
                }}
            />}
            {isLoading && (
                <div className={styles.loaderContainer}>
                    <AppLoader classNames={''} />
                </div>
            )}
        </>
    )
}