import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import Header from './header/Header';
import EventViewer from './event-viewer/EventViewer';
import style from './app-shell.module.scss';
import { useMeetingContext, useUserContext, useOnBehalfContext } from './context/context-helpers';
import { OnbehalfView } from './onbehalf/OnBehalfView';
import { OnbehalfControls } from './onbehalf/OnBehalfControls';
import SettingsApi from '../api/Settings';
import { useAppDispatch } from './context/context-helpers';
import { UserType } from '../api/enums/UserType'
import IParticipantToManage from '../api/models/IParticipantToManage';
import { Link } from 'react-router-dom';



/**
 * AppShell => The app root.
 * 
 * @returns {JSX.Element}
 */
const AppShell: React.FunctionComponent<{}> = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const meeting = useMeetingContext();
	const currentUser = useUserContext();
	const currentOnbehalf = useOnBehalfContext();
	const settingsApi = new SettingsApi(dispatch);

	const [participantsToManage, setParticipantsToManage] = useState<IParticipantToManage[]>([]);


	const fetchUserSettings = async () => {
		settingsApi.getUserSettings()
			.then(({ data }) => {
				dispatch({ type: 'SET_USER_SETTINGS', value: data.loggedUser })

				if (data.onbehalfUser && data.onbehalfUser.userId) {
					dispatch({ type: 'SET_ONBEHALF', value: data.onbehalfUser })
				}

				setParticipantsToManage(data.participantsToManage)
			})
	}

	const isUserManagerOrParticipant = () => {
		return UserType.Manager & currentUser.userType || UserType.Participant & currentUser.userType
	}

	const isUserAdmin = (): boolean => {
		return currentUser.userType >= UserType.Admin;
	}

	const closeEventEditor = () => {
		dispatch({ type: 'CLOSE_EVENT_EDITOR', value: null });
	}

	useEffect(() => {
		fetchUserSettings();
	}, [])

	return (
		<div className={style.appShell}>
			<div className={style.header}>
				<Link to="/mymeetings">
					<img className={style.logo} src="/img/logo-app.svg" alt="" onClick={closeEventEditor} />
				</Link>
				{!isUserManagerOrParticipant() &&
					<OnbehalfControls
						isAdmin={isUserAdmin()}
						usersList={participantsToManage}
						currentOnBehalf={currentOnbehalf.email} />
				}
			</div>
			<div className={style.content}>
				<div className={style.main}>
					<Header />
					<Routes />
				</div>
				<EventViewer event={meeting} />

				{!isUserManagerOrParticipant() && !currentOnbehalf.userId &&
					<OnbehalfView />
				}
			</div>
		</div>
	);
}

export default AppShell;
