import React, { useState, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

/**
* useDebounceSearch => The debounce search hook.
* 
* @returns {JSX.Element}
*/
const useDebounceSearch = (request: any, setIsLoading: any, popupParticipants?: boolean, jobValue?: any, groupValue?: any, departmentValue?: any): any => {
	const [inputValue, setInputValue] = useState('');
	
	// Debounce the original search async function
	const debounceRequest = useMemo(() => {
		return AwesomeDebouncePromise(request, 300)
	}, []);
	
	const search = useAsync(
		async () => {
			setIsLoading(true);
			if (popupParticipants) {
				return debounceRequest(inputValue, 10, 0, jobValue, groupValue, departmentValue);
			} else {
				return debounceRequest(inputValue, 5, 0);
			}
			// à voir si on en a réellement besoin
			// if (inputValue.length === 0) {
			// 	return [];
			// }
		},
		[inputValue]
	);
	
	return {
		inputValue,
		setInputValue,
		search,
	};
}

export default useDebounceSearch;