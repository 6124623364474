import React, { useMemo } from 'react';
import style from './event-viewer-content.module.scss';
import IInvitationParticipant from '../../../api/models/IInvitiationParticipant';
import EventStatePastille from '../../../utils/EventStatePastille';
import { LocalizationHelper } from '../../../utils/localization.helpers';

/**
 * ParticipantSpan props interface.
 * 
 * @interface
 * @property {IInvitationParticipant} participants The participant to display.
 */
interface IParticipantSpanProps {
	participant: IInvitationParticipant;
}

/**
 * ParticipantSpan => The participant span component.
 * 
 * @returns {JSX.Element}
 */
const ParticipantSpan = ({
	participant
}: IParticipantSpanProps): JSX.Element => {
	return (
		<div className={style.participantSpan}>
			<EventStatePastille invitationStatus={participant.invitationStatus} />
			<span className={style.participantName}>
				{participant.firstName} {participant.lastName}
			</span>
			<span className={style.participantConflict}>
				({participant.available ?
				<LocalizationHelper tradkey="EVENTVIEWER.CONTENT.AVAILABLE" /> :
				<LocalizationHelper tradkey="EVENTVIEWER.CONTENT.UNAVAILABLE" />})
			</span>
		</div>
	);
}

export default ParticipantSpan;
