import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

/**
 * The openModal function.
 * 
 * @function
 * @param   {JSX.Element}  component  The component to render.
 * @param   {any}          props      The component props.
 * @param   {string}       className  The modalWrapper custom classname.
 * @returns {null}
 */
const openModal = (component: React.ComponentType<any>, props: any, className?: string) => {
	const container = document.getElementById('modal-container');
	const anchor = document.getElementById('modal-anchor');

	ReactDOM.render(React.createElement(component, props), anchor);
	if (className) {
		anchor.classList.add(className);
	}
	container.classList.remove('hidden');
}

/**
 * The closeModal function.
 * 
 * @function
 * @returns {null}
 */
const closeModal = () => {
	const container = document.getElementById('modal-container');
	const anchor = document.getElementById('modal-anchor');

	ReactDOM.render(null, anchor);
	container.classList.add('hidden');
}

export { openModal, closeModal };
