import React, { useState, useEffect } from 'react';
import AppShell from './components/AppShell';
import AppContext from './components/context/AppContext';
import Toaster from './components/toaster/Toaster';
import "./App.scss";
import ModalWrapper from './components/modal-wrapper/ModalWrapper';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { getAppInsights } from './TelemetryService';
import TelemetryProvider from './telemetry-provider';
 
const AppNotConnected = () => null;

/**
 * App => The app.
 * 
 * @returns {JSX.Element}
 */
const App: React.FunctionComponent<{}> = (): JSX.Element => {
	/** Define if the user is connected. (Default to true until we have an authent) */
	const isConnected = useState(true);
	let appInsights = null;

	/** useEffect that check the localstorage and process authent or not. */
	useEffect(() => {
		// Auth the user
	}, []);

	return (
		<AppContext>
			<TelemetryProvider instrumentationKey="fa08787b-9954-4481-978d-1c2fe163b0ec" after={() => { appInsights = getAppInsights() }}>
				{!isConnected && <AppNotConnected />}
				{isConnected && <AppShell />}
				<Toaster />
				<ModalWrapper />
			</TelemetryProvider>
		</AppContext>
	);
}

export default App;
