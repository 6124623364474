import React, { useState, useMemo } from 'react';
import { LocalizationHelper } from '../../utils/localization.helpers';
import ModalHeader from '../modal-wrapper/template/ModalHeader';
import ModalFooter from '../modal-wrapper/template/ModalFooter';
import ModalContent from '../modal-wrapper/template/ModalContent';
import { closeModal } from '../modal-wrapper/modal-wrapper.helpers';
import IMeeting from '../../api/models/IMeeting';
import { DispatchAction } from '../context/context';
import InvitationsApi from '../../api/Invitations';

/**
 * RefuseInvitationModal props interface.
 * 
 * @interface
 * @property {string}                         title         The popup title.
 * @property {string}                         text          The text to display.
 * @property {React.Dispatch<DispatchAction>} dispatch      The app dispatch function.
 * @property {IMeeting}                       meeting       The current selected meeting.
 * @property {string}					      commentValue  The comment value of the event viewer form.
 * @property {boolean}						  isProposition Define if the invitation is a reschedule or not.
 * @property {any}							  refresh  	   The app refresh function.
 */
interface IRefuseInvitationModalProps {
	title: string;
	text: string;
	dispatch: React.Dispatch<DispatchAction>;
	meeting: IMeeting;
	commentValue: string;
	isProposition: boolean;
	refresh: any;
}

/**
 * RefuseInvitationModal => The refuse invitation modal component.
 * 
 * @returns {JSX.Element}
 */
const RefuseInvitationModal: React.FunctionComponent<IRefuseInvitationModalProps> = ({
	title,
	text,
	dispatch,
	meeting,
	commentValue,
	isProposition,
	refresh
}: IRefuseInvitationModalProps): JSX.Element => {
	/** Define is the request is sent or not. */
	const [isLoading, setIsLoading] = useState(false);
	/** The meeting api instance. */
	const invitationsApi = useMemo(() => new InvitationsApi(dispatch), []);

	const onConfirm = () => {
		if (!isLoading) {
			setIsLoading(true);

			const refuseRequest = isProposition ? invitationsApi.refuseReschedule : invitationsApi.refuse;

			refuseRequest(meeting.invitationId, commentValue)
				.then(() => {
					dispatch({ type: 'SELECT_MEETING', value: {} });
					refresh();
					closeModal();
				}).finally(() => {
					setIsLoading(false);
				});
		}
	}

	return (
		<>
			<ModalHeader title={title} />
			<ModalContent>
				<LocalizationHelper tradkey={text} /> {meeting.authorName} ?
			</ModalContent>
			<ModalFooter
				primaryTitle="GLOBAL.YES"
				primaryAction={onConfirm}
				secondaryTitle="GLOBAL.NO"
				secondaryAction={closeModal}
				isLoading={isLoading}
			/>
		</>
	);
}

export default RefuseInvitationModal;