import React, { useMemo } from 'react';
import { InvitationStatus } from '../api/enums/InvitationStatus';
/**
 * EventStatePastille props interface.
 * 
 * @interface
 * @property {string[]}         [classNames]       The attached class names.
 * @property {InvitationStatus} invitationStatus The invitiation status.
 */
interface IEventStatePastilleProps {
	classNames?: string[];
	invitationStatus: InvitationStatus;
}
/**
 * EventStatePastille => The EventStatePastille component.
 * 
 * @returns {JSX.Element}
 */
const EventStatePastille = ({
	classNames,
	invitationStatus
}: IEventStatePastilleProps): JSX.Element => {
	let invitationState = useMemo(() => {
		switch(invitationStatus) {
			case InvitationStatus.Rescheduled:
                return 'maybe';
			case InvitationStatus.Refused:
			case InvitationStatus.RescheduleRefused:
			case InvitationStatus.Canceled:
				return 'refused';
			case InvitationStatus.Accepted:
                return 'accepted';
			case InvitationStatus.Pending:
				return 'unknow';
		}
	}, [invitationStatus]);
	let invitationicon = useMemo(() => {
		switch(invitationStatus) {
			case InvitationStatus.Accepted:
				return 'far fa-check';
			case InvitationStatus.Refused:
			case InvitationStatus.RescheduleRefused:
			case InvitationStatus.Canceled:
				return 'far fa-times';
			case InvitationStatus.Rescheduled:
				return 'far fa-question';
			case InvitationStatus.Pending:
				return 'far fa-envelope';
		}
	}, [invitationStatus]);
	const classList = classNames && classNames.length > 0 ? classNames.join(" ") : '';
	return	(
		<div className={`pastille ${'pastille--' + invitationState} ${classList}`}>
			<i className={`${invitationicon} pastilleIcon`}></i>
		</div>
	);
}
export default EventStatePastille;