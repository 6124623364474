import React, { useEffect, useState } from 'react';
import SelectComponent from '../../../../utils/SelectComponent';
import moment from 'moment';
import styles from '../time-slot.module.scss';
import { components } from 'react-select';
import { statutForm } from '../../../../enums/formMeetingState';

/**
 * CustomHeaderDate props.
 * 
 * @property {Function} onDateChange Function called when date change.
 */
interface ICustomHeaderDate {
	onDateChange: any
	meetingParent: any;
	setMeeting: any;
	status: statutForm;
}

/**
* CustomHeaderDate => The custom header date component.
* 
* @returns {JSX.Element}
*/
const CustomHeaderDate: React.FunctionComponent<ICustomHeaderDate> = ({
	onDateChange,
	meetingParent,
	setMeeting,
	status,
}: ICustomHeaderDate): JSX.Element => {

	const [optionNumber, setOptionNumber] = useState(0);

	const [canSelect, setCanSelect] = useState(false);

	useEffect(() => {
		if (meetingParent && meetingParent.day !== null) {
			if (moment(meetingParent.day).day() === moment(new Date("04-05-2020")).day()) {
				setOptionNumber(0)
				onDateChange(options[0], false)
			}
			if (moment(meetingParent.day).day() === moment(new Date("04-06-2020")).day()) {
				setOptionNumber(1)
				onDateChange(options[1], false)
			}
			if (moment(meetingParent.day).day() === moment(new Date("04-07-2020")).day()) {
				setOptionNumber(2)
				onDateChange(options[2], false)
			}
			if (moment(meetingParent.day).day() === moment(new Date("04-08-2020")).day()) {
				setOptionNumber(3)
				onDateChange(options[3], false)
			}
		}
	}, [meetingParent])

	const options = [{ key: 0, value: moment(new Date("04-05-2020")).valueOf() },
	{ key: 1, value: moment(new Date("04-06-2020")).valueOf() },
	{ key: 2, value: moment(new Date("04-07-2020")).valueOf() },
	{ key: 2, value: moment(new Date("04-08-2020")).valueOf() }
	];

	const enableSelect = () => {
		setCanSelect(true)
	}

	return (
		<div className={styles.CustomHeaderDate}>
			{!canSelect && (meetingParent.day === '' || meetingParent.day === null) ? (
				<SelectComponent
					className={styles.selectComponent}
					defaultValue={status === statutForm.CREATION ? null : options[optionNumber]}
					components={
						{
							Option: CustomOption,
							SingleValue: CustomSingleValue,
							Control: CustomControl,
							IndicatorSeparator: CustomIndicatorSeparator,
							IndicatorsContainer: CustomIndicatorsContainer
						}
					}
					options={options}
					onChange={(value) => {
						onDateChange(value, true); 
						enableSelect();
					}}
					isMulti={false}
					isSearchable={false}
					isDisabled={status === statutForm.CREATION ? false : true}
					placeholder={'Select day'}
				/>
			) : (
					<SelectComponent
						className={styles.selectComponent}
						defaultValue={status === statutForm.CREATION ? null : options[optionNumber]}
						components={
							{
								Option: CustomOption,
								SingleValue: CustomSingleValue,
								Control: CustomControl,
								IndicatorSeparator: CustomIndicatorSeparator,
								IndicatorsContainer: CustomIndicatorsContainer
							}
						}
						options={options}
						value={options[optionNumber]}
						onChange={(value) => onDateChange(value, true)}
						isMulti={false}
						isSearchable={false}
						isDisabled={status === statutForm.CREATION ? false : true}
						placeholder={'Select day'}
					/>
				)}
		</div>
	);
}

const CustomIndicatorsContainer: React.FunctionComponent<any> = ({ ...props }) => {
	return <components.IndicatorsContainer className={styles.IndicatorsContainer} {...props} />
}

const CustomControl: React.FunctionComponent<any> = ({ ...props }) => {
	return <components.Control className={styles.Control} {...props} />
}

const CustomIndicatorSeparator: React.FunctionComponent<any> = ({ ...props }) => {
	return <components.IndicatorSeparator className={styles.IndicatorSeparator} {...props} />
}

const CustomOption: React.FunctionComponent<any> = ({ timeSlotClass, value, ...props }): JSX.Element => {
	return (
		<components.Option className={styles[timeSlotClass]} {...props} >
			{moment(value).format("dddd, MMMM Do")}
		</components.Option>
	);
}

const CustomSingleValue: React.FunctionComponent<any> = ({ data, ...props }): JSX.Element => {
	return (
		<components.SingleValue className="SingleValue" {...props}>
			<span className={styles.day}>
				{moment(data.value).format("dddd")}
			</span>
			<span className={styles.date}>
				{moment(data.value).format("Do")}
			</span>
		</components.SingleValue>
	);
}

export default CustomHeaderDate;