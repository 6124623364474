import React from 'react';
import { LocalizationHelper } from '../../utils/localization.helpers';
import Select from 'react-select'
import styles from './OnbehalfControls.module.scss'
import OnBehalfApi from '../../api/OnBehalf'
import IParticipantToManage from '../../api/models/IParticipantToManage';
import { useAppDispatch, useUserContext } from '../context/context-helpers';

interface IOnbehalfControlsProps {
    isAdmin: boolean;
    usersList: IParticipantToManage[];
    currentOnBehalf: string;
}

const rooms = [
    {
        label: "5 giga",
        value: "5 giga"
    },
    {
        label: "12 tera",
        value: "5 tera"
    }
];

export const OnbehalfControls: React.FunctionComponent<IOnbehalfControlsProps> = (props: IOnbehalfControlsProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentUser = useUserContext();
    const onBehalfApi = new OnBehalfApi(dispatch);

    const usersListSelect = props.usersList.map(user => ({ label: user.email, value: user.email }))

    const handleUsersSelectChange = selectedOption => {
        onBehalfApi.onBehalf(selectedOption.value)
            .then(({ data }) => {
                dispatch({ type: 'SET_ONBEHALF', value: data})
            });
    }

    const handleRemoveOnBehalf = () => {
        onBehalfApi.removeOnBehalf()
            .then(() => {
                dispatch({ type: 'SET_ONBEHALF', value: {
                    userId: null,
                    userType: null,
                    firstName: '',
                    lastName: '',
                    email: ''
                }})
            });
    }

    return (
        <div className={styles.onBehalfControls}>
            <label>
                <LocalizationHelper tradkey={"ONBEHALF.CONTROLS.CHOOSE_CALENDAR"} /> :
            </label>
            { currentUser.firstName &&
                <span className={ styles.loggedUser }>
                    { currentUser.firstName } { currentUser.lastName } { props.currentOnBehalf && 'for' }
                </span>
            }
            <Select
                className={styles.select}
                autosize={true}
                options={usersListSelect}
                onChange={handleUsersSelectChange}
                value={{
                    label: props.currentOnBehalf,
                    value: props.currentOnBehalf
                }}
                isSearchable={true}
                placeholder="-" />

            { props.currentOnBehalf &&
                <button onClick={() => handleRemoveOnBehalf()} className="transparent" type="button">
                    <i className="far fa-user-times"></i>
                </button>
            }

            {/* props.isAdmin &&
                <>
                    <label>
                        <LocalizationHelper tradkey={"ONBEHALF.CONTROLS.CHOOSE_ROOM"} />
                    </label>
                    <Select
                        className={styles.select}
                        options={rooms}
                        placeholder="-" />
                </>
             */}
        </div>
    )
}