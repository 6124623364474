import React  from 'react';
import { TimeSlotGroup } from '../timeslot-interfaces';
import styles from '../time-slot.module.scss';
import { useResolveTrad } from '../../../../utils/localization.helpers';
import {statutForm} from '../../../../enums/formMeetingState'
	
/**
* TimeSlot props interface.
* 
* @interface
* @property {TimeSlotGroup} group       The time-slot group to render.
* @property {Function}      deleteGroup The function to delete a group.
*/
interface ICustomGroupProps {
	group: TimeSlotGroup;
	deleteGroup: (groupId: number) => void;
	statusForm: statutForm;
}

/**
* CustomGroup => The custom group component.
* 
* @returns {JSX.Element}
*/
const CustomGroup: React.FunctionComponent<ICustomGroupProps> = ({
	group,
	deleteGroup,
	statusForm,
}: ICustomGroupProps): JSX.Element => {
	/** The resolved trad for 'participants' */
	const participantsTrad = useResolveTrad("GENERAL.PARTICIPANTS");

	let content = undefined;
	
	if (group && group.title) {
		switch (group.title) {
			case 'more_groups':
				content = <div className={styles.CustomGroupParticipantsList}>+ 54 {participantsTrad}</div>
				break;
			default:
				content = <div className={styles.CustomGroupName}>
								{ statusForm !== statutForm.PROPOSE ? (
								<i
								className={`${styles.deleteIcon} far fa-times-circle`}
								onClick={() => deleteGroup(group.id)}
								></i>
								) : 
								(<></>)}
								<span className={styles.groupName}>{group.title}</span>
						  </div>;
		}
	}
	
	return <div>{content}</div>;
};

export default CustomGroup;