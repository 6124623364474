import BaseApi from './BaseApi';
import { IApiListResult, IApiResult } from './api';
import IMeeting from './models/IMeeting';
import IInvitationParticipant from './models/IInvitiationParticipant';
import { DispatchAction } from '../components/context/context';
import IParticipantTimeSlots from './models/IParticipantTimeSlots';
import IMeetingDetails from './models/IMeetingDetails';

/**
 * The meetings API class.
 * 
 * @class
 */
class MeetingsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Meetings', dispatch);
    }

	/**
	* Function that get the current user meetings.
	* 
	* @returns {Promise} The request promise.
	*/
	getMeetings = (): Promise<IApiListResult<IMeeting>> => {
		return this._get('', {});
	}

	/**
	* Function that get participant list of a meeting.
	* 
	* @param   {number}  meetingId The meeting id.
	* @returns {Promise} The request promise.
	*/
	getParticipantsByMeetingId = ( meetingId: number ): Promise<IApiListResult<IInvitationParticipant>> => {
		return this._get(`${meetingId}/participants`, {});
	}

	/**
	 * Function that get the meeting details.
	 * 
	 * @param   {number}  meetingId    The meeting id.
	 * @param   {number}  invitationId The invitiation id.
	 * @returns {Promise} The request promise.
	 */
	getMeetingDetails = (meetingId: number, invitationId?: number): Promise<IApiResult<IMeetingDetails>> => {
		return this._get(`${meetingId}/details/${invitationId ? invitationId : ''}`, {});
	}

	getMeetingsByParticipantsIds = (ids: number[], date: string): Promise<IApiListResult<IParticipantTimeSlots>> => {
		return this._post('timeslots', {
			participantIds: ids,
			date
		});
	}

	postMeeting = (participants: number[], startDate: string, endDate: string, cateringId: number, meetinType: number, title: string, description: string, roomId?: any) => {
		return this._post('', {
			Participants: participants,
			StartDate: startDate,
			EndDate: endDate,
			CateringId: cateringId,
			MeetingType: meetinType,
			Title: title,
			Description: description,
			RoomId: roomId ? roomId : null,
		});
	}

	editMeeting = (meetingId: number, participants: number[], startDate: string, endDate: string, cateringId: number, meetinType: number, title: string, description: string, roomId?: any) => {
		return this._put(meetingId.toString(), {
			Participants: participants,
			StartDate: startDate,
			EndDate: endDate,
			CateringId: cateringId,
			MeetingType: meetinType,
			Title: title,
			Description: description,
			RoomId: roomId ? roomId : null,
		});
	}

	deleteMeeting = (meeting: IMeeting): Promise<any> => {
		return this._delete(`${meeting.meetingId}`, {});
	}
}

export default MeetingsApi;