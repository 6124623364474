import React from 'react'
import { DispatchAction } from '../../context/context';
import style from './event-viewer-footer.module.scss';
import { LocalizationHelper } from '../../../utils/localization.helpers';
import { useAppDispatch, useMeetingContext, useRefresh } from '../../context/context-helpers';
import { openModal } from '../../modal-wrapper/modal-wrapper.helpers';
import DeleteMeetingModal from '../../modals/DeleteMeetingModal';
import IMeeting from '../../../api/models/IMeeting';
import MeetingAcceptationSummaryModal from '../../modals/meeting-acceptation-summary-modal/MeetingAcceptationSummaryModal';
import RefuseInvitationModal from '../../modals/RefuseInvitationModal';
import InvitationsApi from '../../../api/Invitations';
import ErrorModal from '../../modals/error-modal/ErrorModal';

export interface IEventViewerAction {
	icon: string;
	text: string;
	className: string[];
	globalClassName: string;
	action: (
		dispatch: React.Dispatch<DispatchAction>,
		meeting?: IMeeting,
		commentValue?: string,
		refreshFct?: any
	) => any;
}
const actions: IEventViewerAction[] = [
	{
		icon: 'far fa-calendar-alt',
		text: 'EVENTVIEWER.FOOTER.ACTION.ADD_TO_CALENDAR',
		className: ['text-icon-btn'],
		globalClassName: 'rounded',
		action: () => null,
	},
	{
		icon: 'far fa-trash-alt',
		text: '',
		className: ['simple-icon-btn'],
		globalClassName: 'circle big',
		action: (dispatch, meeting, commentValue, refreshFct) => {
			openModal(DeleteMeetingModal, {
				title: "CONFIRMATION_MODAL.TITLE.DELETE_EVENT",
				text: "CONFIRMATION_MODAL.TEXT.DELETE_EVENT",
				dispatch,
				meeting: meeting,
				refresh: refreshFct
			})
		},
	},
	{
		icon: 'far fa-pen',
		text: '',
		className: ['simple-icon-btn'],
		globalClassName: 'circle big',
		action: (dispatch) => {
			dispatch({
				type: 'OPEN_EVENT_EDITOR',
				value: {
					shouldUseSelectedItem: true,
					isAReschedule: false
				}
			});
		},
	},
	// Refuse Invitation
	{
		icon: '',
		text: 'GENERAL.REFUSE',
		className: ['text-btn', 'refuse-btn'],
		globalClassName: 'rounded',
		action: (dispatch, meeting, commentValue, refreshFct) => {
			if (!commentValue) {
				dispatch({ type: 'SET_EVENT_VIEWER_COMMENT_AREA_HAS_ERROR', value: true});
			} else {
				openModal(RefuseInvitationModal, {
					title: "REFUSE_INVITATION_MODAL.TITLE.REFUSE_INVITATION",
					text: "REFUSE_INVITATION_MODAL.CONTENT.REFUSE_INVITATION",
					dispatch,
					meeting: meeting,
					commentValue,
					isProposition: false,
					refresh: refreshFct
				});
			}
		},
	},
	{
		icon: '',
		text: 'EVENTVIEWER.FOOTER.ACTION.PROPOSE_NEW_TIME',
		className: ['text-btn'],
		globalClassName: 'rounded',
		action: (dispatch) => {
			dispatch({
				type: 'OPEN_EVENT_EDITOR',
				value: {
					shouldUseSelectedItem: true,
					isAReschedule: true				
				}
			});
		},
	},
	{
		icon: '',
		text: 'EVENTVIEWER.FOOTER.ACTION.ACCEPT_AND_SEND',
		className: ['text-btn'],
		globalClassName: 'rounded',
		action: (dispatch, meeting, commentValue, refreshFct) => {
			const invitationsApi = new InvitationsApi(dispatch);

			dispatch({type: 'SET_EVENT_VIEWER_LOADING', value: true});
			invitationsApi.acceptReschedule(meeting.invitationId)
				.then((res) => {
					openModal(MeetingAcceptationSummaryModal, {
						dispatch,
						meeting: res.data,
						contentKey: 'MEETING_ACCEPTATION_SUMMARY_MODAL.CONTENT.UPDATED_SUMMARY',
						refresh: refreshFct
					})
				}).catch(() => {
					openModal(ErrorModal, {
						title: 'ACCEPT_RESCHEDULE.ERROR_MODAL.TITLE',
						reason: 'ACCEPT_RESCHEDULE.ERROR_MODAL.REASON',
						error: 'ACCEPT_RESCHEDULE.ERROR_MODAL.ERROR'
					})
				}).finally(() => {
					dispatch({type: 'SET_EVENT_VIEWER_LOADING', value: false});
				});
		},
	},
	{
		icon: '',
		text: 'EVENTVIEWER.FOOTER.ACTION.ACCEPT',
		className: ['text-btn'],
		globalClassName: 'rounded',
		action: (dispatch, meeting, commentValue, refreshFct) => {
			const invitationsApi = new InvitationsApi(dispatch);

			dispatch({type: 'SET_EVENT_VIEWER_LOADING', value: true});
			invitationsApi.accept(meeting.invitationId)
				.then((res) => {
					openModal(MeetingAcceptationSummaryModal, {
						dispatch,
						meeting: res.data,
						contentKey: 'MEETING_ACCEPTATION_SUMMARY_MODAL.CONTENT.SUMMARY',
						refresh: refreshFct
					})
				}).finally(() => {
					dispatch({type: 'SET_EVENT_VIEWER_LOADING', value: false});
				});
		},
	},
	// Refuse Proposition
	{
		icon: '',
		text: 'GENERAL.REFUSE',
		className: ['text-btn', 'refuse-btn'],
		globalClassName: 'rounded',
		action: (dispatch, meeting, commentValue, refreshFct) => {
			if (!commentValue) {
				dispatch({ type: 'SET_EVENT_VIEWER_COMMENT_AREA_HAS_ERROR', value: true});
			} else {
				openModal(RefuseInvitationModal, {
					title: "REFUSE_INVITATION_MODAL.TITLE.REFUSE_PROPOSITION",
					text: "REFUSE_INVITATION_MODAL.CONTENT.REFUSE_PROPOSITION",
					dispatch,
					meeting: meeting,
					commentValue,
					isProposition: true,
					refresh: refreshFct
				});
			}
		},
	},
];

/**
 * ActionBtn props interface.
 * 
 * @interface
 * @property {string}		      commentValue The comment section value of the event viewer.
 * @property {IEventViewerAction} action       The element attached's action.
 */
interface IActionBtnProps {
	commentValue: string;
	action: IEventViewerAction,
}

/**
 * EventViewerFooter => The ActionBtn component.
 * 
 * @returns {JSX.Element}
 */
const ActionBtn: React.FunctionComponent<IActionBtnProps> = ({
	commentValue,
	action,
}: IActionBtnProps): JSX.Element => {
	/** The app dispatch function. */
	const dispatch = useAppDispatch();
	/** The current selected meeting. */
	const meeting = useMeetingContext();
	/** The app refresher. */
	const refresh = useRefresh();

	let localClassNames = action.className.map((_class: string) => style[_class] + ' ').join(" ");

	return (
		<button
			className={`${action.globalClassName} ${localClassNames}`}
			onClick={() => action.action(dispatch, meeting, commentValue, refresh)}
		>
			{action.icon && <i className={action.icon}></i>}
			<LocalizationHelper cmpt="span" tradkey={action.text} />
		</button>
	);
}

export default ActionBtn;
export { actions };