import React, { useEffect, useState, useRef } from 'react';
import styles from './TimeSlotTooltip.module.scss';
import { ITimeSlotMeeting } from '../../../../api/models/IParticipantTimeSlots';
import moment from 'moment';

interface ITimeSlotTooltipPosition {
    left: string;
    top: string;
}

/**
 * TimeSlotTooltip props interface.
 * 
 * @interface
 * @property {Function} position Position of the TimeSlotTooltip.
 */
interface ITimeSlotTooltipProps {
    position: ITimeSlotTooltipPosition;
    visible: boolean;
    onTooltipVisibleChange(visible: boolean): void;
    meetings: ITimeSlotMeeting[];
}

/**
 * TimeSlot => The time slot component.
 * 
 * @returns {JSX.Element}
 */
const TimeSlotTooltip: React.FunctionComponent<ITimeSlotTooltipProps> = ({
    visible,
    position,
    meetings,
    onTooltipVisibleChange
}: ITimeSlotTooltipProps): JSX.Element => {
    const tooltipRef = useRef<HTMLDivElement>();
    const [isVisible, setIsVisible] = useState<boolean>(visible)

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)

        return () => {
            document.removeEventListener('mousedown', handleClick, false)
        }
    }, []);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible])

    const handleClickOutside = () => {
        onTooltipVisibleChange(false);
    }

    const handleClick = (e) => {
        if(tooltipRef.current.contains(e.target) || e.target.classList.contains('rct-item_content')) {
            return;
        }

        handleClickOutside();
    }

    return (
        <div className={`${styles.tooltip} ${isVisible ? styles.visible : ''}`} style={position} ref={tooltipRef}>
            { meetings.length && (
                <div className={ styles.header }>
                    <span className={ styles.participantFullName }>{ meetings[0].participantFullName }</span>
                    <span className={ styles.meetingDay }>{ moment(meetings[0].startDate).format('dddd Do') }</span>
                </div>
            ) }

            <ul className={ styles.meetingsList }>
                { meetings.map((meeting, index) => (
                    <li key={ index }>
                        <div className={ styles.meetingTime }>{ moment(meeting.startDate).format('h:mm') } - { moment(meeting.endDate).format('h:mm') }</div>
                        <div className={ styles.meetingTitle }>{ meeting.title }</div>
                    </li>
                )) }
            </ul>
        </div>
    )
}

export default TimeSlotTooltip;