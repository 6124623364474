import React, { ReactNode } from 'react';
import style from '../modal-wrapper.module.scss';

/**
 * ModalContent props interface.
 * 
 * @interface
 * @property {ReactNode} children        The children to render inside.
 * @property {string}    customClassname An attached custom class.
 */
interface IModalContentProps {
	children: ReactNode;
	customClassname?: string;
}

/**
 * ModalContent => The modal content component.
 * 
 * @returns {JSX.Element}
 */
const ModalContent: React.FunctionComponent<IModalContentProps> = ({
	children,
	customClassname = ""
}: IModalContentProps): JSX.Element => {
	return (
		<div className={`${style.modalContent} ${customClassname}`}>
			{children}
		</div>
	);
}

export default ModalContent;