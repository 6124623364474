import React from 'react';
import Select, { Props, OptionTypeBase } from 'react-select';

/**
 * The SelectComponent props interface.
 * 
 * @interface
 * @property {string}     customClassName A custom classname to attach to the component.
 * @property {Props<any>} props           The props of the react-select component.
 */
interface ISelectComponentProps extends Props<OptionTypeBase> {
	customClassName?: string;
}

/**
 * SelectComponent => Encapsultation of the react-select component.
 * 
 * @param   {ISelectComponentProps} props The component props.
 * @returns {JSX.Element}
 */
const SelectComponent = ({customClassName, ...props}: ISelectComponentProps) => {
	return <Select {...props} />
}

export default SelectComponent;