import BaseApi from './BaseApi';
import { IApiResult } from './api';
import { DispatchAction, Context } from '../components/context/context';

/**
 * The OnBehalf API class.
 * 
 * @class
 */
class OnBehalfApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Auth', dispatch);
    }

	/**
	* 
	* 
	* @param   {string}  onBehalfEmail The onBehalfEmail
	* @returns {Promise} The request promise.
	*/
	onBehalf = (onBehalfEmail): Promise<IApiResult<Context.IUser>> => {
		return this._put(`onbehalf/${encodeURIComponent(onBehalfEmail)}`, null);
	}

	/**
	*
	*
	* @returns {Promise} The request promise.
	*/
	removeOnBehalf = (): Promise<IApiResult<any>> => {
		return this._put('removeonbehalf', null);
	}
}

export default OnBehalfApi;