import React, { useMemo, useEffect, useState } from 'react'
import NotificationsApi from '../../api/Notifications';
import { useAppDispatch, useNotificationsCountRefresh } from '../context/context-helpers';
import style from './Header.module.scss';

/**
 * The MenuLink component props interface.
 * 
 * @interface
 */
interface INotificationsCountProps {
}

/**
 * NotificationsCount component => The component that render the notifications count.
 * 
 * @param   {INotificationsCountProps} props The component props.
 * @returns {JSX.Element}    The component.
 */
const NotificationsCount: React.SFC<INotificationsCountProps> = ({

}: INotificationsCountProps): JSX.Element => {
	/** The app dispatch. */
	const dispatch = useAppDispatch();
	/** The notifications api. */
	const notificationsApi = useMemo(() => new NotificationsApi(dispatch), []);
	/** The fetched notification count. */
	const [notificationsCount, setNotificationsCount] = useState<number>(0);
	/** The notificationCountRefresh context. */
	const shouldNotificationCountRefresh = useNotificationsCountRefresh();

	const getCount = () => {
		notificationsApi.getNotificationsCount()
			.then((res) => {
				setNotificationsCount(res.data && res.data.count ? res.data.count : 0);
		})
	}

	useEffect(() => {
        if (shouldNotificationCountRefresh) {
            getCount();
            dispatch({type: 'SHOULD_NOTIFICATIONS_COUNT_REFRESH', value: false});
        }
    }, [shouldNotificationCountRefresh])

	useEffect(() => {
		getCount();
	}, []);

	return (
		<>
			{notificationsCount > 0 && (
				<div className={style.notificationsCount}>
					{notificationsCount}
				</div>
			)}
		</>
	);
}

export default NotificationsCount;