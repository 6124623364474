import React, { useEffect, useState } from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { Context } from '../context/context';
import * as dates from 'date-arithmetic';
import { useSettingsContext } from '../context/context-helpers';

interface IMeetingsCalendarViewProps {
    date: Date;
}

/**
 * MeetingsCalendarView => The customized calendar view.
 *
 * @param   {IMeetingsCalendarViewProps} props The component props.
 * @returns {JSX.Element}
 */
export const MeetingsCalendarView = (props: IMeetingsCalendarViewProps): JSX.Element => {
    const settingsContext: Context.ISettingsContext = useSettingsContext();

    const getRange = (startDate, endDate) => {

        let start = startDate
        let end = endDate

        let current = start
        let range: string[] = []

        while (dates.lte(current, end, 'day')) {
            range.push(current)
            current = dates.add(current, 1, 'day')
        }

        return range
    }

    const date = props.date
    const defaultRange = getRange(date, dates.add(date, 2, 'day'));
    
    const [viewRange, setViewRange] = useState(defaultRange);

    useEffect(() => {
        let computedRange = getRange(date, settingsContext.endDay)
        setViewRange(computedRange)
    }, [settingsContext])

    return (
        <TimeGrid {...props} range={viewRange} eventOffset={15} />
    )
}

MeetingsCalendarView.title = () => {
    return ''
}

MeetingsCalendarView.navigate = (date, action) => {
    switch (action) {
        case "PREV":
            return dates.add(date, -1, 'day')
        case "NEXT":
            return dates.add(date, 1, 'day')
        default:
            return date
    }
}