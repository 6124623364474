import React from 'react';
import style from './event-editor.module.scss';
import { LocalizationHelper } from '../../utils/localization.helpers';
/**
 * StepTitle props interface.
 * 
 * @interface
 * @property {number} stepNumber The number of the step.
 * @property {string} title      The step title.
 */
interface IStepTitleProps {
    stepNumber: number;
    title: string;
}
/**
 * StepTitle => The event editor popup.
 * 
 * @returns {JSX.Element}
 */
const StepTitle: React.FunctionComponent<IStepTitleProps> = ({
    stepNumber,
    title
}: IStepTitleProps): JSX.Element => {
    return (
        <div className={style.stepTitle}>
            <div className={style.stepNumber}>{stepNumber}</div>
            <LocalizationHelper tradkey={title} className={style.stepContent} />
        </div>
    )
}
export default StepTitle;