import React, { useState, useMemo } from 'react';
import { LocalizationHelper } from '../../utils/localization.helpers';
import ModalHeader from '../modal-wrapper/template/ModalHeader';
import ModalFooter from '../modal-wrapper/template/ModalFooter';
import ModalContent from '../modal-wrapper/template/ModalContent';
import { closeModal } from '../modal-wrapper/modal-wrapper.helpers';
import IMeeting from '../../api/models/IMeeting';
import MeetingsApi from '../../api/Meetings';
import { DispatchAction } from '../context/context';
import { useEffect } from 'react';
import { statutForm } from '../../enums/formMeetingState';


/**
 * DeleteMeetingModal props interface.
 * 
 * @interface
 * @property {string}                         title    The popup title.
 * @property {string}                         text     The text to display.
 * @property {React.Dispatch<DispatchAction>} dispatch The app dispatch function.
 * @property {IMeeting}                       meeting  The current selected meeting.
 */
interface IConfirmationCreateMeetingProps {
    title: string;
    meetingTitle: string;
    roomName: string;
    error: number;
    dispatch: React.Dispatch<DispatchAction>;
    meeting: IMeeting;
    success: boolean;
    statut: statutForm;
}

/**
 * DeleteMeetingModal => The delete meeting modal component.
 * 
 * @returns {JSX.Element}
 */
const ConfirmationCreateMeeting: React.FunctionComponent<IConfirmationCreateMeetingProps> = ({
    title,
    meetingTitle,
    roomName,
    error,
    dispatch,
    success,
    statut,
}: IConfirmationCreateMeetingProps): JSX.Element => {
    /** Define is the request is sent or not. */
    const [isLoading, setIsLoading] = useState(false);
    const [messageError, setMessageError] = useState();
    /** The meeting api instance. */

    const meetingsApi = useMemo(() => new MeetingsApi(dispatch), []);


    useEffect(() => {
        let message = '';
        if (error === 0) {
            message = 'No room available'
        } else if (error === 1) {
            message = 'Invalid Participant'
        } else if (error === 2) {
            message = 'No invitation found'
        } else if (error === 3) {
            message = 'No meeting Found'
        } else if (error === 4) {
            message = 'Invalid meeting type'
        } else if (error === 5) {
            message = 'Not authenticated'
        } else if (error === 6) {
            message = 'Identity cookie not found'
        } else if (error === 7) {
            message = 'Missing parameter'
        }
        else {
            message = 'Unknown error'
        }
        setMessageError(message)
    }, [error])

    const onConfirm = () => {
        closeModal()
        if (success) {
            dispatch({ type: 'CLOSE_EVENT_EDITOR', value: null })
        }
    }

    return (
        <>
                {/* si on est en creation */}
                {success && statut === statutForm.CREATION ? (
                    <>
                    <ModalHeader title={"Your meeting has been created"} />
                    <ModalContent>
                    <div>
                        <div>Meeting Title: {meetingTitle}</div>
                        <div>Room Name: {roomName}</div>
                    </div>
                    </ModalContent>
                    </>
                ) : ( <></>)}
                {!success && statut === statutForm.CREATION ? (
                    <>
                    <ModalHeader title={"Your meeting has not been created"} />
                    <ModalContent>
                        <div>{messageError}</div>
                    </ModalContent>
                    </>
                ) : (<></>)}
                {/* si on est en edition */}
                {success && statut === statutForm.EDIT ? (
                    <>
                    <ModalHeader title={"Your meeting has been edited"} />
                    <ModalContent>
                        <div>the meeting has been edited</div>
                    </ModalContent>
                    </>
                ) : ( <></>)}
                {!success && statut === statutForm.EDIT ? (
                    <>
                    <ModalHeader title={"Your meeting has not been edited"} />
                    <ModalContent>
                        <div>{messageError}</div>
                    </ModalContent>
                    </>
                ) : (<></>)}
                {/* si on est en propose */}
                {success && statut === statutForm.PROPOSE ? (
                    <>
                    <ModalHeader title={"Your proposal has been sent"} />
                    <ModalContent>
                        <div>the proposal has been sent</div>
                    </ModalContent>
                    </>
                ) : ( <></>)}
                {!success && statut === statutForm.PROPOSE ? (
                    <>
                    <ModalHeader title={"Your proposal hasn't been sent"} />
                    <ModalContent>
                        <div>{messageError}</div>
                    </ModalContent>
                    </>
                ) : (<></>)}

            <ModalFooter
                primaryTitle="GLOBAL.OK"
                primaryAction={onConfirm}
                isLoading={isLoading}
            />
        </>
    );
}

export default ConfirmationCreateMeeting;