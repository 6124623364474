import Axios, { AxiosInstance } from 'axios';
import { DispatchAction } from '../components/context/context';

/**
 * The baseAPI class.
 * 
 * @class
 * @param {string} _scope   The targeted api scope.
 * @param {string} _baseUrl The base url.
 */
class BaseApi {

	private scope: string = null;
	private baseUrl: string = null;
	private instance: AxiosInstance = null;

	constructor(_scope: string, dispatch: React.Dispatch<DispatchAction>, _baseUrl?: string) {
		this.scope = _scope === '' ? _scope : '/' + _scope;
		this.baseUrl = _baseUrl ? _baseUrl : "/api";

		this.instance = Axios.create();

		this.instance.interceptors.response.use(undefined, (error) => {
			switch(error.response.status) {
				case 404 :
					dispatch({type: 'REQUEST_ERROR', value: 'ERROR_404'});
					break;
				case 401 :
					window.location.href = "/api/auth";
					break;
				default:
					dispatch({type: 'REQUEST_ERROR', value: error.response.status});
					break;
			}
			return Promise.reject(error.response.data);
		});
	}

	/**
    * Encapsulation of the axios get request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     params   request params.
    * @returns {Promise} The request promise.
    */
	protected _get(endpoint: string, params: any): Promise<any> {
		return this.instance.get(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`,
			{ params }
		);
	}

	/**
    * Encapsulation of the axios post request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     params   request params.
    * @returns {Promise} The request promise.
    */
	protected _post(endpoint: string, params: any): Promise<any> {
		return this.instance.post(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`,
			params
		);
	}

	/**
    * Encapsulation of the axios delete request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     params   request params.
    * @returns {Promise} The request promise.
    */
	protected _delete(endpoint: string, params: any): Promise<any> {
		return this.instance.delete(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`,
			params
		);
	}

	/**
    * Encapsulation of the axios put request.
    * 
    * @param   {string}  endpoint request endpoint.
    * @param   {any}     params   request params.
	* @param   {any}     headers  request headers.
    * @returns {Promise} The request promise.
    */
	protected _put(endpoint: string, params: any, headers?: any): Promise<any> { 
		return this.instance.put(`${this.baseUrl}${this.scope}${endpoint === '' ? endpoint : '/' + endpoint}`,
			params,
			headers
		);
	}
}

export default BaseApi;