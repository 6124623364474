import React from 'react';
import { Data, CustomHeaderPropsChildrenFnProps} from 'react-calendar-timeline';
import styles from '../time-slot.module.scss';

/**
* CustomHeaderTime => The custom header time component.
* 
* @returns {JSX.Element}
*/
const CustomHeaderTime: React.FunctionComponent<CustomHeaderPropsChildrenFnProps<Data>> = ({
	headerContext,
	getRootProps,
	getIntervalProps,
	showPeriod,
	data,
}: CustomHeaderPropsChildrenFnProps<Data>): JSX.Element => {
	return (
		<div {...getRootProps()}>
		{headerContext.intervals.map(interval => {
			return (
			<div {...getIntervalProps({interval})}>
				<div className={`sticky ${styles.CustomHeaderTime}`}>
					{interval.startTime.format('HH:00')}
				</div>
			</div>
			)
		})}
		</div>
	)
};

export default CustomHeaderTime;