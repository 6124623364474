import React from 'react';
import style from './modal-wrapper.module.scss';

/**
 * ModalWrapper => The modal wrapper component.
 * 
 * @returns {JSX.Element}
 */
const ModalWrapper: React.FunctionComponent<{}> = (): JSX.Element => {
	return (
		<>
			<div id="modal-container" className={`${style.modalContainer} hidden`}>
				<div id="modal-anchor" className={`${style.modalWrapper}`} />
			</div>
		</>
	);
}

export default ModalWrapper;