import React, { useMemo, useEffect } from 'react';
import style from './event-viewer-footer.module.scss';
import IMeeting from '../../../api/models/IMeeting';
import ActionBtn, { IEventViewerAction } from './ActionBtn';
import { Context } from '../../context/context';
import { getRightActions, getLeftActions } from './actions-helper';
import { useRouteMatch } from 'react-router-dom';

/**
 * EventViewerFooter props interface.
 * 
 * @interface
 * @property {string}				commentValue The comment section value of the event viewer.
 * @property {IMeeting}             event        The current selected event.
 * @property {Context.IUserContext} user         The current connected user.
 * @property {Context.IUser}        onBehalfUser The current onbehalf connected user.
 */
interface IEventViewerFooterProps {
	commentValue: string;
	event: IMeeting;
	user: Context.IUserContext;
	onBehalfUser: Context.IUser;
}

/**
 * EventViewerFooter => The event viewer footer component.
 * 
 * @returns {JSX.Element}
 */
const EventViewerFooter: React.FunctionComponent<IEventViewerFooterProps> = ({
	commentValue,
	event,
	user,
	onBehalfUser,
}: IEventViewerFooterProps): JSX.Element => {

	let isNotificationPage = useRouteMatch("/notifications");
	let isNotif = isNotificationPage && isNotificationPage.isExact === true ? true : false

	const leftBtnsList: JSX.Element[] = useMemo(() => {
		return getLeftActions(event, user, onBehalfUser, isNotif)
			.map((_action: IEventViewerAction, index: number) =>
				<ActionBtn
					commentValue={commentValue}
					key={index}
					action={_action}
				/>
			);
	}, [event, user, onBehalfUser, commentValue]);

	const rightBtnsList: JSX.Element[] = useMemo(() => {
		return getRightActions(event, user, onBehalfUser, isNotif)
			.map((_action: IEventViewerAction, index: number) =>
				<ActionBtn
					commentValue={commentValue}
					key={index}
					action={_action}
				/>
			);
	}, [event, user, onBehalfUser]);


	return (
		<div className={style.eventViewerFooter}>
			<div className={style.eventViewerFooterContainer}>
				<div className={`${style.btnsContainer} ${style.leftBtns}`}>{leftBtnsList}</div>
				<div className={`${style.btnsContainer} ${style.rigthBtns}`}>{rightBtnsList}</div>
			</div>
		</div>
	);
}

export default EventViewerFooter;
