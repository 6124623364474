import React from 'react';
import { useLocalizationContext } from '../components/context/context-helpers';

/**
 * The useResolveTrad hook.
 * 
 * @param   {string} tradKey The key to translate.
 * @returns {string} The translated key.
 */
const useResolveTrad = (tradkey): string => {
	/** The current localization context. */
	const {tradKeys} = useLocalizationContext();

	if (tradKeys && tradKeys[tradkey]) {
		return(tradKeys[tradkey]);
	} else {
		return(tradkey);
	}
}

/**
 * The LocalizationHelper props interface.
 * 
 * @interface
 * @property {string} className The classes of the rendered component.
 * @property {string} cmpt      The component type / html tag of the rendered component.
 * @property {string} content   The key to translate.
 */
interface ILocalizationHelperProps {
	className?: string;
	cmpt?: string;
	tradkey: string;
}

/**
 * The LocalizationHelper component.
 * 
 * @param   {ILocalizationHelperProps} props The component props.
 * @returns {JSX.Element} The LocalizationHelper component.
 */
const LocalizationHelper = ({ className, cmpt, tradkey }: ILocalizationHelperProps): JSX.Element => {
	/** The translated content to display. */
	const output = useResolveTrad(tradkey);

	return React.createElement(cmpt || 'span', { className: className || ''}, output);
}

export { useResolveTrad, LocalizationHelper };