import React, { useReducer, ReactNode, useEffect } from 'react';
import { Context, DispatchAction } from './context';
import appReducer from './app-reducer';

/** The default value for the context */
const defaultContextValue: Context.IContext = {
	localization: {
		locale: "en",
		tradKeys: {},
	},
	user: {
		userId: 0,
		firstName: '',
		lastName: '',
		email: '',
		userType: 1
	},
	onBehalfUser: {
		userId: null,
		userType: null,
		firstName: '',
		lastName: '',
		email: ''
	},
	meeting: {
		meetingId: null,
		invitationId: null,
		title: '',
		description: '',
		startDate: null,
		endDate: null,
		meetingType: null,
		cateringId: null,
		roomName: '',
		roomId: null,
		authorName: '',
		authorId: null,
		invitationStatus: null
	},
	settings: {
		startDay: null,
        endDay: null,
        startHour: null,
		endHour: null,
		scrollTime: null,
        affiliations: [],
        functions: [],
        caterings: []
	},
	notifications: {
		count: 0
	},
	error: {
		type: null
	},
	eventEditionState: {
		isOpen: false,
		shouldUseSelectedItem: false,
		isAReschedule: false
	},
	calendarRefresh: false,
	isEventViewerLoading: false,
	isEventViewerCommentAreaInvalid: false,
	notificationsCountRefresh: false,
	notificationsRefresh: false,
	locationPath: ''
}

/** The app context variable. */
const AppStateContext: React.Context<[Context.IContext, React.Dispatch<DispatchAction>]> = React.createContext<[Context.IContext, React.Dispatch<DispatchAction>]>([defaultContextValue, () => null]);

/**
 * AppContext props interface.
 * 
 * @interface
 * 
 * @param {React.ReactNode} children The children to render.
 */
interface IAppContextProps {
	children: ReactNode;
}

/**
 * AppContext => Provide the context to the entire app.
 * 
 * @param   {IAppContextProps} props The component props.
 * @returns {JSX.Element}
 */
const AppContext: React.FunctionComponent<IAppContextProps> = ({
	children
}: IAppContextProps): JSX.Element => {
	/** The context value */
	const contextValue = useReducer(appReducer, defaultContextValue);

	/** Use effect that fill the localizationContext. */
	useEffect(() => {
		(async () => {
			const [value, dispatch] = contextValue;
			const tradKeys = await import('../../trad/en.json');
			dispatch({ type: 'LOCALIZATION_INIT', value: tradKeys});
		})();
	}, [])

	return (
		<AppStateContext.Provider value={contextValue}>
			{children}
		</AppStateContext.Provider>
	);
}

export default AppContext;
export { AppStateContext };
