import BaseApi from './BaseApi';
import IRoom from './models/IMeeting';
import { IApiListResult } from './api';
import { DispatchAction } from '../components/context/context';


/**
 * The rooms API class.
 * 
 * @class
 */
class RoomsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
		// TODO modifier meetings avec Rooms
        super('Meetings', dispatch);
    }

	/**
	* Function that find a room.
	* 
	* @returns {Promise} The request promise.
	*/
	
	findARoom = (startDate: string, endDate: string): Promise<any> => {
		return this._get('available', {
			startDate: startDate,
			endDate: endDate,
		});
	}

}

export default RoomsApi;