import React from 'react';
import BaseApi from './BaseApi';
import { IApiListResult } from './api';
import { DispatchAction } from '../components/context/context';
import IParticipant from './models/IParticipant';

/**
 * The participants API class.
 * 
 * @class
 */
class ParticipantsApi extends BaseApi {

    constructor(dispatch: React.Dispatch<DispatchAction>) {
        super('Participants', dispatch);
    }

	/**
	 * Search participants
	 * 
	 * @param  {number}  functionId    The function id.
	 * @param  {number}  affiliationId The affiliation id.
	 * @param  {string}  keyword       The keyword search.
	 * @param  {number}  pageSize      The page size.
	 * @param  {number}  page          The page number.
	 * @return {Promise} The request promise.
	 */
	search = (
		keyword: string,
		pageSize: number,
		page: number,
		functionId?: number,
		affiliationId?: number,
		departmentId?: number,
	): Promise<IApiListResult<IParticipant>> => {
		return this._get('search', {
			functionId,
			affiliationId,
			departmentId,
			keyword,
			pageSize,
			page
		});
	} 
}

export default ParticipantsApi;